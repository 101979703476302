import * as React from "react";
import { definedNotNull } from "../../Utils/functional";
import { useRandomId } from "../hooks";
import { HelpTooltip } from "./HelpTooltip";

interface Props<T extends string | number> {
  defaultOption?: string;
  id?: string;
  disabled?: boolean;
  className?: string;
  label: React.ReactNode;
  value: T | undefined;
  onChange: (event: React.FormEvent<HTMLSelectElement>) => void;
  options: { value: T; label: string }[];
  feedback?: string;
  tooltipMessage?: string;
}
export function FormSelect<T extends string | number>(props: Props<T>) {
  const randomId = useRandomId();
  const id = props.id || randomId;

  let options = props.options.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));

  if (definedNotNull(props.defaultOption)) {
    options = [
      <option key="default" value="">
        {props.defaultOption}
      </option>,
      ...options,
    ];
  }

  const invalid = Boolean(props.feedback);

  const className = props.className ? props.className : "";
  return (
    <div className={`form-group ${className}`}>
      <label htmlFor={id} className="form-input-label mr-1">
        {props.label}
      </label>
      {props.tooltipMessage && (
        <HelpTooltip tooltipText={props.tooltipMessage} />
      )}
      <select
        className={`custom-select ${invalid ? "is-invalid" : ""}`}
        disabled={props.disabled}
        onChange={props.onChange}
        name={id}
        id={id}
        value={props.value || (props.defaultOption && "")}
        aria-invalid={invalid}
        aria-describedby={invalid ? id + "_error" : undefined}
      >
        {options}
      </select>
      {invalid ? (
        <div id={id + "_error"} className="invalid-feedback">
          {props.feedback}
        </div>
      ) : null}
    </div>
  );
}
