import * as React from "react";
import { Location } from "react-router";
import { Link } from "react-router-dom";
import { PermissionType, UserPermissions } from "../../permissions";
import { getSettings } from "../../settings";
import withRouter, { WithRouterProps } from "../../Utils/withRouter";
import IstLogo from "./IST_logotype.svg";

const inDemo = getSettings().releaseStage !== "production";

export interface MenuItem {
  label: string;
  url?: string;
  somePermission?: PermissionType[];
  orgPermission?: PermissionType;
  onlyInDemo?: boolean;
  exactMatch?: true;
  type?: "link" | "header" | "spacer";
}

interface Props extends WithRouterProps {
  hidden?: boolean;
  menuItems: MenuItem[];
  userPermissions?: UserPermissions | null;
  skipPermissionCheck?: boolean;
  location: Location;
}

class SideMenuInner extends React.PureComponent<Props, never> {
  public render() {
    const { hidden, location, userPermissions } = this.props;
    if (hidden) {
      return <div className="sideMenu" />;
    }

    let lastItemWasSpace = false;
    return (
      <div className="sideMenu" role="navigation">
        <div className="list-group side-list-group">
          {this.props.skipPermissionCheck ? (
            <React.Fragment>
              <div className="list-group-item text-danger">
                Debug: Showing all items, regardless of your permissions
              </div>
              <div className="list-group-item side-menu-item" />
            </React.Fragment>
          ) : null}
          {this.props.menuItems.map((item, index) => {
            const active = item.exactMatch
              ? location.pathname === item.url
              : item.url
              ? location.pathname.startsWith(item.url)
              : false;
            const className = `list-group-item side-menu-item${
              active ? " active" : ""
            }${item.type ? ` ${item.type}` : ""}`;
            if (
              !this.props.skipPermissionCheck &&
              (!userPermissions ||
                (item.somePermission &&
                  !item.somePermission.some(
                    (perm) => userPermissions.some[perm]
                  )) ||
                (item.orgPermission &&
                  !userPermissions.org[item.orgPermission]) ||
                (item.onlyInDemo && !inDemo))
            ) {
              return null;
            }

            // Make sure two spaces don't render next to each other
            const isSpace = item.type === "spacer";
            if (isSpace && lastItemWasSpace) return null;
            lastItemWasSpace = isSpace;

            return item.url ? (
              <Link to={item.url} key={index} className={"link " + className}>
                {item.label}
              </Link>
            ) : (
              <div key={index} className={className}>
                {item.label}
              </div>
            );
          })}
        </div>
        <div className="icon-box">
          <img className="ist-logo" src={IstLogo} alt="IST-logo" />
        </div>
      </div>
    );
  }
}

export const SideMenu = withRouter(SideMenuInner);
