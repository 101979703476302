import gql from "graphql-tag";
import * as _ from "lodash";
import * as React from "react";
import { MutationFunction, QueryResult } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import { graphql } from "@apollo/client/react/hoc";
import { scroller } from "react-scroll";
import {
  AdminContext,
  Translation,
  withAdminContext,
  withTranslation,
} from "../../App/reducer";
import { Button, Checkbox, ISTContainer, Loading } from "../../Common";
import FormSearchSelect from "../../Common/components/FormSearchSelect";
import { IconButton } from "../../Common/components/IconButton";
import { fullHistoryRecordFragment } from "../../Common/fragments";
import {
  withRecordClassResponsesHistory,
  withUnmarkFavorite,
} from "../../Common/mutations";
import {
  getCombinedIdCustomerId,
  getPartsFromCombinedIdCustomerId,
} from "../../Common/utils";
import { createPseudoID } from "../../Utils/pseudoID";
import { assertUnreachable } from "../../Utils/typeHelpers";
import * as gqltypes from "../../gqltypes";
import { links } from "../links";
import { StudentGroupResponses } from "./StudentGroupResponses";
import { NavigateFunction, Params } from "react-router";
import withRouter from "../../Utils/withRouter";

type SchoolUnit = gqltypes.schools["organisation"]["schoolUnits"][0];
type StudentGroup = gqltypes.ClassesOnSchoolUnit["schoolUnit"]["classes"][0];
interface StudentGroupsProps {
  tr: Translation["tr"];
  studentGroups: StudentGroup[];
  selectedStudentGroup: string | undefined;
  schoolUnitId: string;
  customerId: string;
  selectStudentGroup: (group: StudentGroup) => void;
  favorites: gqltypes.schools["me"]["favorites"];
  deletefavorite: (groupId: string) => void;
  saveFavorite: (group: StudentGroup) => void;
  showGroupsWithoutPublications: boolean;
  setShowGroupsWithoutPublication(value: boolean): void;
}

const getGroupTypeLabel = (
  tr: Translation["tr"],
  groupType: gqltypes.Code_GroupType
) => {
  switch (groupType) {
    case gqltypes.Code_GroupType.Avdelning:
      return tr("groupTypeDepartment");
    case gqltypes.Code_GroupType.Klass:
      return tr("groupTypeClass");
    case gqltypes.Code_GroupType.Mentor:
      return tr("groupTypeMentor");
    case gqltypes.Code_GroupType.Ovrigt:
      return tr("groupTypeOther");
    case gqltypes.Code_GroupType.Schema:
      return tr("groupTypeScheme");
    case gqltypes.Code_GroupType.Undervisning:
      return tr("groupTypeEducation");
    case gqltypes.Code_GroupType.Personalgrupp:
      return tr("groupTypePersonalgrupp");
    case gqltypes.Code_GroupType.Provgrupp:
      return tr("groupTypeProvgrupp");
    default:
      assertUnreachable(groupType);
      return "";
  }
};

const getGroupTypeColor = (groupType: gqltypes.Code_GroupType) => {
  switch (groupType) {
    case gqltypes.Code_GroupType.Avdelning:
      return "success";
    case gqltypes.Code_GroupType.Klass:
      return "primary";
    case gqltypes.Code_GroupType.Mentor:
      return "info";
    default:
      return "primary";
  }
};

function StudentGroupsSelectionTableInner(props: StudentGroupsProps) {
  const { tr } = props;

  const handleShowGroupsWithoutPublicationChange = React.useCallback(() => {
    props.setShowGroupsWithoutPublication(!props.showGroupsWithoutPublications);
  }, [props]);

  return (
    <div>
      <div className="px-content">
        <Checkbox
          id="showGroupWithoutPublications"
          label={tr("viewAnswerShowGroupsWithoutPublications")}
          checked={!props.showGroupsWithoutPublications}
          onChange={handleShowGroupsWithoutPublicationChange}
        />
      </div>

      {props.studentGroups.length === 0 ? (
        <div className="alert alert-info m-content">
          {tr("viewAnswersSchoolUnitNoClasses")}
        </div>
      ) : (
        <table className="table mt-content mb-5 table-no-end-line">
          <thead>
            <tr>
              <th scope="col">{tr("viewAnswerColumnGroup")}</th>
              <th scope="col">{tr("viewAnswerColumnGroupType")}</th>
              <th scope="col">{tr("viewAnswerColumnPublications")}</th>
              <th scope="col">{tr("viewAnswerColumnFavorite")}</th>
            </tr>
          </thead>
          <tbody className="clickable-rows">
            {props.studentGroups.map((group) => {
              const favorite = props.favorites.find(
                (f) =>
                  f.type === gqltypes.RecordHistoryType.classResponses &&
                  (f.data as any).studentGroupId === group.id
              );
              return (
                <tr
                  key={group.id}
                  onClick={() => props.selectStudentGroup(group)}
                  className={
                    group.id === props.selectedStudentGroup
                      ? "selected"
                      : undefined
                  }
                >
                  <td>{group.displayName}</td>
                  <td>
                    {group.groupType ? (
                      <span
                        className={`badge badge-${getGroupTypeColor(
                          group.groupType
                        )}`}
                      >
                        {getGroupTypeLabel(tr, group.groupType)}
                      </span>
                    ) : null}
                  </td>
                  <td>{group.sentFormsMetadata.publications}</td>
                  <td>
                    {favorite ? (
                      <IconButton
                        iconClass="fas fa-star"
                        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                          e.stopPropagation();
                          props.deletefavorite(favorite.id);
                        }}
                        title={tr("adminStartFavoriteRemoveTitle")}
                        aria-label={tr(
                          "adminStartFavoriteRemoveAriaLabel",
                          favorite.label
                        )}
                      />
                    ) : (
                      <IconButton
                        iconClass="far fa-star"
                        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                          e.stopPropagation();
                          props.saveFavorite(group);
                        }}
                        title={tr("favoriteAddFavoriteLabel")}
                        aria-label={tr(
                          "favoriteAddFavoriteAriaLabel",
                          group.displayName
                        )}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

interface Props extends Translation, AdminContext {
  recordClassResponsesHistory: MutationFunction<
    gqltypes.recordClassResponsesHistory,
    gqltypes.recordClassResponsesHistoryVariables
  >;
  deleteFavorite: MutationFunction<
    gqltypes.deleteFavorite,
    gqltypes.deleteFavoriteVariables
  >;
  schoolData: gqltypes.schools & QueryResult;
  navigate: NavigateFunction;
  params: Params;
  expired: boolean;
  toggleExpired: (expired: boolean) => void;
}

interface State {
  schoolUnits: SchoolUnit[] | null;
  showGroupsWithoutPublications: boolean;
  expired: boolean;
}

class StudentGroups extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let schoolUnits = null;
    if (this.props.schoolData.organisation) {
      schoolUnits = _.orderBy(
        this.props.schoolData.organisation.schoolUnits.filter(
          (su) => su.classes.length
        ),
        "displayName"
      );
    }
    this.state = {
      schoolUnits,
      showGroupsWithoutPublications: false,
      expired: false,
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      prevProps.schoolData.organisation !== this.props.schoolData.organisation
    ) {
      if (
        this.props.schoolData &&
        this.props.schoolData.organisation &&
        this.props.schoolData.organisation.schoolUnits
      ) {
        const schoolUnits = _.orderBy(
          this.props.schoolData.organisation.schoolUnits.filter(
            (su) => su.classes.length
          ),
          "displayName"
        );
        this.setState({ schoolUnits });
      }
    }
  }
  public toggleExpired = (expired: boolean) => {
    this.setState({
      expired: expired,
    });
  };

  public render() {
    const { tr } = this.props;
    const schoolData = this.props.schoolData;
    const params = this.props.params;
    const schoolUnits = this.state.schoolUnits;
    const selectedSchoolUnit = schoolUnits
      ? schoolUnits.find(
          (su) => su.id === params.unitId && su.customerId === params.customerId
        )
      : undefined;

    const selectedValue =
      params.customerId && params.unitId && selectedSchoolUnit
        ? {
            value: getCombinedIdCustomerId(selectedSchoolUnit),
            label: selectedSchoolUnit.displayName,
          }
        : null;

    const sentFormsMetadata:
      | gqltypes.schools_organisation_schoolUnits_classes_sentFormsMetadata
      | undefined = schoolUnits
      ?.find((unit) => unit.id === params.unitId)
      ?.classes.find((cls) => cls.id === params.groupId)?.sentFormsMetadata;

    return (
      <div id="manage-forms">
        <h1>{tr("viewAnswersTitle")}</h1>
        <p className="col-12 col-md-9 p-0 pb-3">
          {tr("viewAnswersDescription")}
        </p>
        <ISTContainer header={tr("viewAnswersHeader")}>
          <div className="p-content">{tr("viewAnswersUsageDescription")}</div>
          {schoolData.loading ? (
            <Loading className="p-content" />
          ) : (
            <div>
              {schoolUnits && schoolUnits.length <= 10 ? (
                <div className="row p-content">
                  {schoolUnits.map((su) => (
                    <div key={su.id} className="col-auto py-3">
                      <Button
                        label={su.displayName}
                        level={
                          params.unitId === su.id ? "primary" : "secondary"
                        }
                        onClick={() => {
                          if (params.unitId !== su.id) {
                            this.handleChangeSchoolUnit(su);
                          }
                        }}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <form className="p-content">
                  <FormSearchSelect
                    aria-label={tr("viewAnswersUsageDescription")}
                    menuShouldScrollIntoView
                    placeholder={tr("viewAnswersChooseSchoolUnit")}
                    noOptionsMessage={() => tr("noSearchHits")}
                    maxMenuHeight={320}
                    isLoading={!schoolUnits}
                    options={
                      schoolUnits
                        ? schoolUnits.map((school) => ({
                            value: getCombinedIdCustomerId(school),
                            label: school.displayName,
                          }))
                        : []
                    }
                    value={selectedValue}
                    onChange={(item: any) => {
                      if (item) {
                        this.handleChangeSchoolUnit(
                          getPartsFromCombinedIdCustomerId(item.value!)!
                        );
                      }
                    }}
                  />
                </form>
              )}

              {params.unitId && selectedSchoolUnit && (
                <Query<
                  gqltypes.ClassesOnSchoolUnit,
                  gqltypes.ClassesOnSchoolUnitVariables
                >
                  query={GET_CLASSES}
                  variables={{
                    id: selectedSchoolUnit.id,
                    customerId: selectedSchoolUnit.customerId,
                    context: this.props.adminContext,
                  }}
                  fetchPolicy="cache-first"
                >
                  {({ loading, error, data }) => {
                    if (loading || !data) {
                      return <Loading className="p-content" />;
                    }
                    if (error) {
                      return (
                        <div className="alert alert-warning m-content">
                          {tr("errorFetchingData")}
                        </div>
                      );
                    }
                    return (
                      <StudentGroupsSelectionTableInner
                        tr={tr}
                        selectStudentGroup={(group) =>
                          this.handleStudentGroupChange(
                            selectedSchoolUnit,
                            group
                          )
                        }
                        customerId={selectedSchoolUnit.customerId}
                        selectedStudentGroup={params.groupId}
                        studentGroups={_.orderBy(
                          data.schoolUnit.classes.filter(
                            (sg) =>
                              this.state.showGroupsWithoutPublications ||
                              sg.sentFormsMetadata.publications !== 0
                          ),
                          ["groupType", "displayName"]
                        )}
                        schoolUnitId={params.unitId!}
                        favorites={this.props.schoolData.me.favorites}
                        deletefavorite={this.deleteFavorite}
                        saveFavorite={(group) =>
                          this.saveFavorite(selectedSchoolUnit, group)
                        }
                        showGroupsWithoutPublications={
                          this.state.showGroupsWithoutPublications
                        }
                        setShowGroupsWithoutPublication={(value: boolean) => {
                          this.setState({
                            showGroupsWithoutPublications: value,
                          });
                        }}
                      />
                    );
                  }}
                </Query>
              )}
            </div>
          )}
        </ISTContainer>
        {params.customerId && params.unitId && params.groupId && (
          <div id="student-group-responses-container">
            <StudentGroupResponses
              customerId={params.customerId}
              schoolUnitId={params.unitId}
              studentGroupId={params.groupId}
              pathWithoutPublication={links.admin.publication.answers({
                customerId: params.customerId,
                schoolUnitId: params.unitId,
                studentGroupId: params.groupId,
              })}
              showGroupsWithoutPublications={
                this.state.showGroupsWithoutPublications
              }
              sentFormsMetadata={sentFormsMetadata}
            />
          </div>
        )}
      </div>
    );
  }

  private saveFavorite = (school: SchoolUnit, group: StudentGroup) => {
    const schoolUnitId = this.props.params.unitId!;
    const historyInfo = {
      data: {
        customerId: school.customerId,
        schoolUnitId,
        studentGroupId: group.id,
      },
      label: `${group.displayName}, ${school.displayName}`,
      type: gqltypes.RecordHistoryType.classResponses,
    };
    const variables = {
      input: { isFavorite: true, ...historyInfo },
      context: this.props.adminContext,
    };
    this.props.recordClassResponsesHistory({
      variables,
      optimisticResponse: {
        recordClassResponsesHistory: {
          __typename: "ClassResponsesHistoryRecord",
          id: createPseudoID(),
          ...historyInfo,
          data: {
            ...historyInfo.data,
            __typename: "ClassResponsesHistoryData",
          },
        } as gqltypes.recordClassResponsesHistory["recordClassResponsesHistory"],
      },
      update: (
        store,
        response: {
          data: {
            recordClassResponsesHistory: gqltypes.recordClassResponsesHistory["recordClassResponsesHistory"];
          };
        }
      ) => {
        const data = store.readQuery<gqltypes.schools>({
          query: schoolsQuery,
          variables,
        });
        if (data === null) {
          throw new Error("Could not read query 'schoolsQuery'");
        }

        // Create a new array with the existing favorites and the new favorite
        const newFavorites = [
          ...data.me.favorites,
          {
            ...response.data.recordClassResponsesHistory,
            data: {
              ...(response.data.recordClassResponsesHistory
                .data as gqltypes.recordClassResponsesHistory_recordClassResponsesHistory_ClassResponsesHistoryRecord_data),
              __typename: "ClassResponsesHistoryData",
            },
            __typename: "ClassResponsesHistoryRecord",
          },
        ];

        // Write the new array back to the cache
        store.writeQuery({
          query: schoolsQuery,
          data: {
            ...data,
            me: {
              ...data.me,
              favorites: newFavorites,
            },
          },
          variables,
        });
      },
    });
  };

  private deleteFavorite = async (favoriteId: string) => {
    const variables = { id: favoriteId, context: this.props.adminContext };
    try {
      await this.props.deleteFavorite({
        variables,
        optimisticResponse: {
          deleteFavorite: {
            __typename: "ClassResponsesHistoryRecord",
            id: favoriteId,
          } as gqltypes.deleteFavorite["deleteFavorite"],
        },
        update: (
          store,
          response: {
            data: {
              deleteFavorite: gqltypes.deleteFavorite["deleteFavorite"];
            };
          }
        ) => {
          const data = store.readQuery<gqltypes.schools>({
            query: schoolsQuery,
            variables,
          });
          if (data === null) {
            throw new Error("Could not read query 'schoolsQuery'");
          }
          const returnedRecord = response.data.deleteFavorite;
          if (!returnedRecord) {
            throw new Error("Deletion failed");
          }
          const updatedFavorites = data.me.favorites.filter(
            (f) => f.id !== returnedRecord.id
          );
          store.writeQuery({
            query: schoolsQuery,
            data: {
              ...data,
              me: {
                ...data.me,
                favorites: updatedFavorites,
              },
            },
            variables,
          });
        },
      });
    } catch (error) {
      console.error("Error deleting favorite:", error);
      throw new Error("Deletion failed");
    }
  };

  private handleStudentGroupChange = async (
    schoolUnit: SchoolUnit,
    group: StudentGroup
  ) => {
    const data = {
      customerId: schoolUnit.customerId,
      schoolUnitId: schoolUnit.id,
      studentGroupId: group.id,
    };

    try {
      // Await the recording of history before proceeding
      await this.props.recordClassResponsesHistory({
        variables: {
          input: {
            label: `${group.displayName}, ${schoolUnit.displayName}`,
            type: gqltypes.RecordHistoryType.classResponses,
            data,
          },
          context: this.props.adminContext,
        },
      });

      // Trigger navigation and refetching of data
      this.props.navigate(links.admin.publication.answers(data));
      await this.props.schoolData.refetch();

      // Call scroll logic
      this.scrollToContainer();
    } catch (error) {
      console.error("Error handling student group change:", error);
    }
  };

  // Scroll method with retry mechanism
  private scrollToContainer = (retries = 3, delay = 100) => {
    const element = document.getElementById(
      "student-group-responses-container"
    );
    if (element) {
      scroller.scrollTo("student-group-responses-container", {
        duration: 600,
        delay: 70,
        smooth: true,
        offset: -30,
        onComplete: this.handleScrollComplete,
      });
    } else if (retries > 0) {
      console.warn(
        "Element 'student-group-responses-container' not found. Retrying..."
      );
      setTimeout(() => this.scrollToContainer(retries - 1, delay), delay);
    } else {
      console.warn(
        "Element 'student-group-responses-container' not found after retries."
      );
    }
  };

  private handleScrollComplete = () => {
    console.log("Scroll operation completed.");
  };

  private handleChangeSchoolUnit = (su: { id: string; customerId: string }) => {
    this.props.navigate(
      links.admin.publication.answers({
        customerId: su.customerId,
        schoolUnitId: su.id,
      })
    );
  };
}

const GET_CLASSES = gql`
  query ClassesOnSchoolUnit($id: ID!, $customerId: ID!, $context: Context!) {
    schoolUnit(id: $id, customerId: $customerId, context: $context) {
      id
      classes {
        id
        customerId
        displayName
        groupType
        sentFormsMetadata {
          publications
          numFormsSent
          numCompleteAnswers
          activePublications
          expiredPublications
        }
      }
    }
  }
`;

const schoolsQuery = gql`
  query schools($context: Context!) {
    organisation(context: $context) {
      id
      schoolUnits(filter: { permissionType: read }) {
        id
        customerId
        displayName
        classes {
          sentFormsMetadata {
            publications
            activePublications
            expiredPublications
          }
          id
          customerId
        }
      }
    }
    me {
      id
      favorites(context: $context) {
        ...FullHistoryRecord
      }
    }
  }
  ${fullHistoryRecordFragment}
`;

const withFormsData = graphql<Props, gqltypes.schools>(schoolsQuery, {
  name: "schoolData",
  options: (props) => ({
    variables: {
      context: props.adminContext,
    },
    fetchPolicy: "cache-first",
  }),
});

export const StudentGroupsContainer = _.flowRight(
  withTranslation,
  withAdminContext,
  withRouter,
  withRecordClassResponsesHistory,
  withFormsData,
  withUnmarkFavorite
)(StudentGroups);
