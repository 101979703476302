import { createSelector } from 'reselect';
import { StoreState } from '../store'; 
import { AdminContext } from '../App/reducer';
import { connect } from 'react-redux';

const selectContext = (state: StoreState) => state.app.context;

export const withAdminContext = <T>(component: any): React.ComponentType<any> =>
    connect<AdminContext, {}, any, StoreState>((state) => ({
      adminContext: selectAdminContext(state),
    }))(component);

export const selectAdminContext = createSelector(
  [selectContext],
  (context) => ({
    org: context.org,
    language: context.language,
  })
);