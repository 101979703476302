import React, { useMemo, useState, useCallback, useEffect } from "react";
import { definedNotNull } from "../../Utils/functional";
import { useRandomId } from "../hooks";
import { Collapse } from "reactstrap";
import { useTranslation } from "../../App/reducer";
import { Tooltip } from "./Tooltip";
import { Loading } from "./Loading";

interface PublicationOption {
  value: string | number;
  label: string;
  validTo: string;
}

interface Props<T extends string | number> {
  defaultOption?: string;
  id?: string;
  disabled?: boolean;
  className?: string;
  label: React.ReactNode;
  value: T | undefined;
  onChange: (value: T) => void;
  options: PublicationOption[];
  feedback?: string;
  tooltipMessage?: string;
  isExpired?: boolean;
}

const groupOptionsByMonthYear = (options: PublicationOption[]) => {
  return options.reduce((groupedOptions, option) => {
    const { label, validTo, value } = option;
    const monthYear = new Date(validTo).toLocaleString("default", {
      month: "long",
      year: "numeric",
    });
    groupedOptions[monthYear] = groupedOptions[monthYear] || [];
    groupedOptions[monthYear].push({ value, label });
    return groupedOptions;
  }, {} as Record<string, { value: string | number; label: string }[]>);
};

const FormSelectGroupedCategories = <T extends string | number>({
  defaultOption,
  id,
  disabled,
  className,
  label,
  value,
  onChange,
  options,
  feedback,
  tooltipMessage,
  isExpired,
}: Props<T>) => {
  const { tr } = useTranslation();
  const selectId = useRandomId();
  const groupedOptions = useMemo(
    () => groupOptionsByMonthYear(options),
    [options]
  );
  const [isToggle, setIsToggle] = useState(true);

  const handleToggle = useCallback(() => {
    setIsToggle((prev) => !prev);
  }, []);

  const isTextOverflowing = (element: HTMLElement): boolean => {
    return element.scrollWidth > element.clientWidth;
  };

  const [overflowingRefs, setOverflowingRefs] = useState<{
    [key: string]: boolean;
  }>({});

  const noPublicationMessage = useMemo(() => {
    return isExpired
      ? tr("publicationViewAnswersNoExpiredPublicationsFound")
      : tr("publicationViewAnswersNoActivePublicationsFound");
  }, [isExpired, tr]);

  useEffect(() => {
    const newOverflowingRefs: { [key: string]: boolean } = {};
    Object.entries(groupedOptions).forEach(([monthYear, publications]) => {
      publications.forEach((publication, index) => {
        const refKey = `${monthYear}-${index}-${publication.value}`;
        const element = document.getElementById(refKey);
        if (element && isTextOverflowing(element)) {
          newOverflowingRefs[refKey] = true;
        }
      });
    });
    setOverflowingRefs(newOverflowingRefs);
  }, [groupedOptions]);

  return (
    <div
      className={`form-group ${className}`}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <div className="container-fluid">
        <div
          className="row h-100"
          style={
            isToggle
              ? { background: "var(--blue-xx-light)" }
              : { background: "var(--light)" }
          }
          onClick={handleToggle}
        >
          <h4
            className="my-auto"
            style={isToggle ? { fontWeight: "bold" } : { fontWeight: "normal" }}
          >
            {label}
            <i className={`fas fa-caret-${isToggle ? "up" : "down"} ml-1`} />
          </h4>
        </div>
        <div className="row">
          <div
            className="col-md-12 col-sm-12 pl-0 pr-0 h-100"
            style={{
              boxShadow:
                "0 2px 0px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Collapse isOpen={isToggle} className="mt-2">
              <div
                style={{
                  maxHeight: "50vh",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {Object.keys(groupedOptions).length > 0 ? (
                  <ul
                    className="form-control"
                    id={id || selectId}
                    style={{
                      listStyle: "none",
                      padding: 0,
                      width: "100%",
                      height: "100%",
                      boxSizing: "border-box",
                      border: "none",
                      textAlign: "left",
                    }}
                  >
                    {Object.entries(groupedOptions).map(
                      ([monthYear, publications]) => (
                        <li
                          key={monthYear}
                          className="text-capitalize font-weight-bold ml-2 p-2"
                        >
                          <span>{monthYear}</span>
                          {publications.length > 0 && (
                            <ul style={{ listStyle: "none", padding: 0 }}>
                              {publications.map((publication, index) => {
                                const tooltipId = `tooltip-${monthYear.replace(
                                  /\s+/g,
                                  "-"
                                )}-${index}`;
                                const refKey = `${monthYear}-${index}-${publication.value}`;

                                return (
                                  <React.Fragment key={refKey}>
                                    <li
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        padding: "4px",
                                        boxSizing: "border-box",
                                        border: "none",
                                        textAlign: "left",
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft:
                                          value === publication.value
                                            ? "0"
                                            : "16px",
                                      }}
                                    >
                                      {value === publication.value && (
                                        <span>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-check"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                          </svg>
                                        </span>
                                      )}
                                      <button
                                        type="button"
                                        value={publication.value}
                                        onClick={() =>
                                          onChange(publication.value as T)
                                        }
                                        disabled={disabled}
                                        className="text-truncate"
                                        style={{
                                          border: "none",
                                          background: "none",
                                        }}
                                      >
                                        <span
                                          className="d-block text-left"
                                          style={{
                                            maxWidth: "100%",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                          }}
                                          id={refKey}
                                        >
                                          {overflowingRefs[refKey] ? (
                                            <Tooltip
                                              target={tooltipId}
                                              content={publication.label}
                                              message={publication.label}
                                            />
                                          ) : (
                                            publication.label
                                          )}
                                        </span>
                                      </button>
                                    </li>
                                    {index < publications.length - 1 && (
                                      <hr style={{ margin: "4px 0" }} />
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </ul>
                          )}
                        </li>
                      )
                    )}
                  </ul>
                ) : (
                  <p
                    style={{
                      width: "100%",
                      padding: "10px",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "black",
                      fontSize: "14px",
                    }}
                  >
                    {noPublicationMessage}
                  </p>
                )}
              </div>
            </Collapse>
          </div>
        </div>
      </div>
      {definedNotNull(feedback) && (
        <div className="form-feedback">{feedback}</div>
      )}
    </div>
  );
};

export default React.memo(FormSelectGroupedCategories);
