import React from "react";
import { useTranslation } from "../../App/reducer";
import { copyToClipboard } from "../../Utils/dom";
import { Button } from "./Button";
import { HelpTooltip } from "./HelpTooltip";

interface Props {
  getElementToCopy: () => HTMLElement | null;
  text?: string;
  tooltipText?: string;
  style?: React.CSSProperties;
}

export const ClipboardCopyButton = (props: Props) => {
  const { tr } = useTranslation();

  const handleCopy = () => {
    const element = props.getElementToCopy();
    if (element) {
      copyToClipboard(element);
    } else {
      console.error("Element to copy is not defined");
    }
  };

  return (
    <React.Fragment>
      <Button
        icon={<i className="far fa-clipboard" />}
        label={tr("copyTableLabel")}
        level="secondary"
        onClick={handleCopy}
        style={props.style}
      />
      {props.tooltipText && (
        <span className="ml-2">
          <HelpTooltip tooltipText={props.tooltipText} />
        </span>
      )}
    </React.Fragment>
  );
};
