import React, { useCallback, useMemo, forwardRef } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import gql from "graphql-tag";
import { fullResponseFragment } from "../../Common/fragments";
import {
  Translation,
  useAdminContext,
  useTranslation,
} from "../../App/reducer";
import * as gqltypes from "../../gqltypes";
import { NameWithPotentialLink } from "../../Common/components/Utils";
import {
  getComponentPermissionDisplayName,
  SimplePermission,
} from "../../permissions";
import {
  CREATE_RESPONSE_MUTATION,
  CREATE_RESPONSE_MUTATION_TYPES,
} from "../../Common/mutations";
import { GenericError } from "../../Common/components/GenericError";
import { assertUnreachable } from "../../Utils/typeHelpers";
import {
  Button,
  Loading,
  Table,
  TableColumnData,
  TableHeader,
  TableRow,
  TableSortOrder,
} from "../../Common";
import { links } from "../links";
import { predicateComponentVisible } from "./CreateForm";
import { Tooltip } from "../../Common/components/Tooltip";
import { displayAnswer, SYMBOLS } from "../form";
import * as tooltips from "../../Common/tooltips";
import { Time } from "../../Common/components/Time";
import { emptyPublicationResponse } from "../../Common/queries";
import { groupPublicationRecipients_studentGroup_publicationRecipients } from "../../gqltypes";

const studentResponsesQuery = gql`
  query groupPublicationRecipients(
    $groupId: ID!
    $customerId: ID!
    $publicationId: ID!
    $context: Context!
  ) {
    studentGroup(id: $groupId, customerId: $customerId, context: $context) {
      publicationRecipients(publicationId: $publicationId) {
        user {
          id
          source
          customerId
          name
        }
        id
        modifyPermission
        responses(filter: LastValidOrElseLastPartiallySigned) {
          ...FullResponse
        }
      }
      id
    }
  }
  ${fullResponseFragment}
`;

type Extras = "signers" | "date" | "status" | "language" | "email";

interface ExtraColumnData {
  id: Extras;
  default?: boolean;
}

const getExtraColumnLabel = (tr: Translation["tr"], id: Extras) => {
  switch (id) {
    case "date":
      return tr("responsesExtraDateLabel");
    case "language":
      return tr("responsesExtraLanguageLabel");
    case "signers":
      return tr("responsesExtraSignersLabel");
    case "status":
      return tr("responsesExtraStatusLabel");
    case "email":
      return tr("responsesSignersEmailLabel");
    default:
      assertUnreachable(id);
  }
};

type Filter = "hideUnanswered";

interface FilterColumnData {
  id: Filter;
  default?: boolean;
}

const shortenQuestion = (question: string, maxLen = 30) => {
  return question.length > maxLen
    ? question.substr(0, maxLen) + "..."
    : question;
};

interface NewTableProps {
  publicationId: string;
  groupId: string;
  customerId: string;
  components: gqltypes.FormComponent[];
  selectedComponents: gqltypes.FormComponent[];
  selectedPredicateComponents: gqltypes.FormPredicateComponent[];
  selectedQuestions: gqltypes.FormQuestion[];
  selectedQuestionsState: { [key: string]: boolean };
  selectedExtraColumns: ExtraColumnData[];
  filters: { [key in Filter]: boolean };
  viewForm: (id: string) => void;
  initialSortOrder: TableSortOrder[];
  sortOrderChanged: (order: TableSortOrder[]) => void;
  permissions: SimplePermission;
  expired: boolean;
}

const NewTable = forwardRef<any, NewTableProps>(
  (
    {
      publicationId,
      groupId,
      customerId,
      components,
      selectedComponents,
      selectedPredicateComponents,
      selectedQuestions,
      selectedQuestionsState,
      selectedExtraColumns,
      filters,
      viewForm,
      initialSortOrder,
      sortOrderChanged,
      permissions,
      expired,
    },
    ref
  ) => {
    const adminContext = useAdminContext();
    const { tr } = useTranslation();
    const { data, loading, error } = useQuery(studentResponsesQuery, {
      variables: { groupId, customerId, publicationId, context: adminContext },
      fetchPolicy: "cache-first",
    });

    const [createPublication, { loading: creatingResponse }] = useMutation<
      CREATE_RESPONSE_MUTATION_TYPES["data"],
      CREATE_RESPONSE_MUTATION_TYPES["variables"]
    >(CREATE_RESPONSE_MUTATION);

    const navigate = useNavigate();

    const pushQuestion = useCallback(
      (cols: TableHeader[], question: gqltypes.FormQuestion): TableHeader[] => {
        cols.push({
          key: question.id,
          element: (
            <span title={question.question}>
              {question.shortName
                ? question.shortName
                : shortenQuestion(question.question, 20)}
            </span>
          ),
        });
        return cols;
      },
      []
    );

    const questionsThatIsChecked = useMemo(
      () =>
        selectedQuestions.filter((q) => selectedQuestionsState[q.id] === true),
      [selectedQuestions, selectedQuestionsState]
    );

    const questionCols = useMemo(
      () => questionsThatIsChecked.reduce(pushQuestion, [] as TableHeader[]),
      [questionsThatIsChecked, pushQuestion]
    );

    const centerClassName = "text-center";

    const formPermissions: Set<gqltypes.FormComponentPermission> = useMemo(
      () => new Set(components.map((c) => c.permission)),
      [components]
    );

    const missingPerms = useMemo(
      () => [...formPermissions].filter((p) => !permissions[p]),
      [formPermissions, permissions]
    );

    const missingPermsText = useMemo(
      () =>
        missingPerms
          .map((missing) => getComponentPermissionDisplayName(missing, tr))
          .join(", "),
      [missingPerms, tr]
    );

    const rows = data?.studentGroup.publicationRecipients.map(
      (
        recipient: groupPublicationRecipients_studentGroup_publicationRecipients
      ) => {
        const responseForm =
          recipient.responses.find((res) => res.status === "valid") ||
          recipient.responses.find((res) => res.status === "partially_signed");

        const response =
          responseForm &&
          responseForm.response &&
          responseForm.response.components;

        const questionColAnswers = questionCols.reduce((keys, col) => {
          const predicateComponent = selectedPredicateComponents.find((com) =>
            com.questions.some((q) => q.id === col.key)
          );

          const component =
            predicateComponent ||
            selectedComponents.find((com) =>
              com.questions.some((q) => q.id === col.key)
            );

          if (!component) {
            throw new Error(
              "Failed to find component with question that has id " + col.key
            );
          }

          const question =
            component && component.questions.find((q) => q.id === col.key);

          const componentResponse =
            response &&
            response.find((com: any) => com.componentId === component!.id);

          const answer =
            componentResponse &&
            componentResponse.questions &&
            componentResponse.questions.find(
              (q: any) => q.questionId === col.key
            );

          const redacted =
            (componentResponse && componentResponse.redacted) || false;

          const wasSeen = predicateComponent
            ? responseForm && responseForm.response
              ? predicateComponentVisible(
                  components,
                  responseForm.response,
                  predicateComponent
                )
              : true
            : true;

          const content = redacted ? (
            <Tooltip
              target={"tooltip_" + responseForm!.id + col.key}
              content={`${SYMBOLS.REDACTED} ${tr("viewAnswerRedactedWarning")}`}
              message={tr(
                "viewAnswerRedactedWarningDescription",
                getComponentPermissionDisplayName(component.permission, tr)
              )}
            />
          ) : !wasSeen ? (
            tooltips.predicateComponentUnseenTooltip(tr, col.key)
          ) : answer ? (
            displayAnswer(
              tr,
              question as gqltypes.FormQuestionWithOptionsUnion,
              answer
            )
          ) : null;

          keys[col.key] = {
            content,
          };
          return keys;
        }, {} as { [id: string]: TableColumnData });

        const userName = recipient.user ? (
          recipient.user.name
        ) : (
          <i>{tr("failedToFetchUser")}</i>
        );

        const validAnswer: boolean = responseForm
          ? responseForm.status === "valid"
          : false;
        const partiallySigned: boolean = responseForm
          ? responseForm.status === "partially_signed"
          : false;
        const signRole:
          | gqltypes.PublicationResponseSignRole
          | null
          | undefined = responseForm?.signRole;
        const rowClassName = responseForm
          ? validAnswer
            ? "table-success"
            : "table-info"
          : "";

        const hasAnswer = Boolean(responseForm);

        return {
          key: recipient.id,
          className: rowClassName,
          hidden: filters.hideUnanswered && !hasAnswer,
          columns: {
            name: {
              content: (
                <>
                  {partiallySigned ? (
                    <Tooltip
                      target={
                        "tooltip_" +
                        responseForm!.id +
                        "partially_signed_warning"
                      }
                      content={SYMBOLS.PARTIALLY_SIGNED + " "}
                      message={tr("viewAnswerPartiallySignedDescription")}
                    />
                  ) : null}
                  {signRole === "admin" ? (
                    <Tooltip
                      target={"tooltip_" + responseForm!.id + "admin_signed"}
                      content={SYMBOLS.ADMIN_ANSWER + " "}
                      message={tr("viewAnswerAdminSignedDescription")}
                    />
                  ) : null}
                  <NameWithPotentialLink
                    name={userName}
                    user={recipient.user}
                  />
                </>
              ),
              sortValue: userName,
              preventsClick: true,
            },

            date: {
              content: responseForm ? (
                <Time date={responseForm.modified} />
              ) : null,
              sortValue: responseForm ? responseForm.modified : "",
            },

            status: {
              content: responseForm ? (
                responseForm.status === "valid" ? (
                  <i className="fa fa-check text-success" />
                ) : (
                  <i className="fas fa-signature" />
                )
              ) : null,
              className: centerClassName,
              sortValue: responseForm ? responseForm.status : "",
            },

            signers: {
              sortValue:
                responseForm && responseForm.signatures
                  ? responseForm.signatures.length
                  : 0,
              content: responseForm
                ? responseForm.signatures
                  ? responseForm.signatures.map((signature: any, i: any) => {
                      const user = signature.educloudUser
                        ? signature.educloudUser
                        : signature.signer;

                      return (
                        <div
                          className="mr-2"
                          key={user ? user.id : signature.educloudUserId || i}
                        >
                          <NameWithPotentialLink
                            name={
                              user ? (
                                user.name
                              ) : (
                                <i>{tr("failedToFetchUser")}</i>
                              )
                            }
                            user={user}
                          />
                        </div>
                      );
                    })
                  : ""
                : null,
            },

            language: {
              content: responseForm ? responseForm.language : null,
              className: centerClassName,
            },

            ...questionColAnswers,

            show_answer: {
              content: responseForm ? (
                <>
                  <Link
                    title={tr("viewAnswerShowForm")}
                    aria-label={tr("viewAnswerAriaShowForm", userName)}
                    to={links.admin.publication.response(responseForm.id)}
                    state={{ expired: expired }}
                  >
                    <i
                      aria-hidden="true"
                      className="fas fa-chevron-circle-right"
                    />
                  </Link>
                </>
              ) : recipient.modifyPermission && !expired ? (
                <Button
                  label={tr("viewAnswerFillForm")}
                  title={tr("viewAnswerAriaFillForm", userName)}
                  level="link"
                  className="p-0"
                  size="btn-sm"
                  disabled={creatingResponse}
                  onClick={async () => {
                    const result = await createPublication({
                      variables: {
                        recipientId: recipient.id,
                        response: emptyPublicationResponse,
                      },
                    });
                    const responseId =
                      result.data?.createPublicationResponse.id;

                    if (!responseId)
                      throw new Error("Failed to create publication response");

                    navigate(links.admin.publication.response(responseId));
                  }}
                />
              ) : null,
            },
          },
        };
      }
    );

    const extraCols: TableHeader[] = [
      {
        key: "name",
        element: tr("viewAnswerColumnName"),
        usingSortValue: true,
      },
      {
        key: "date",
        element: getExtraColumnLabel(tr, "date"),
        hidden: !selectedExtraColumns.some((i) => i.id === "date"),
        usingSortValue: true,
      },
      {
        key: "signers",
        element: getExtraColumnLabel(tr, "signers"),
        hidden: !selectedExtraColumns.some((i) => i.id === "signers"),
        usingSortValue: true,
      },
      {
        key: "status",
        element: getExtraColumnLabel(tr, "status"),
        hidden: !selectedExtraColumns.some((i) => i.id === "status"),
        className: centerClassName,
        usingSortValue: true,
      },
      {
        key: "language",
        element: getExtraColumnLabel(tr, "language"),
        hidden: !selectedExtraColumns.some((i) => i.id === "language"),
        className: centerClassName,
      },
      {
        key: "email",
        element: getExtraColumnLabel(tr, "email"),
        hidden: !selectedExtraColumns.some((i) => i.id === "email"),
        usingSortValue: true,
      },
    ];

    const count =
      selectedComponents.reduce((acc, com) => acc + com.questions.length, 0) +
      selectedExtraColumns.length;

    const headers = extraCols
      .concat(questionCols)
      .concat({ element: null, key: "show_answer", unsortable: true });

    if (loading && !data?.studentGroup) {
      return <Loading />;
    }

    if (error || !data?.studentGroup) {
      return <GenericError title={tr("genericLoadError")} />;
    }

    return (
      <>
        {missingPerms.length && permissions.write_publication_response ? (
          <div className="alert alert-warning">
            {tr("createAnswerMissingReadPermissions", missingPermsText)}
          </div>
        ) : null}
        <Table
          tableRef={ref}
          initialOrder={
            initialSortOrder.length
              ? initialSortOrder.map((so) => so.col)
              : "name"
          }
          initialOrderDir={
            initialSortOrder.length
              ? initialSortOrder.map((so) => so.dir)
              : "asc"
          }
          topHeaders={selectedComponents.map((com) => {
            return {
              key: com.id,
              ref: com.questions.map((q) => q.id),
              element: (
                <div
                  className="text-center mr-2 border-bottom"
                  title={com.title}
                >
                  {shortenQuestion(com.title, Math.max(35 - 3 * count, 20))}
                </div>
              ),
            };
          })}
          headers={headers}
          rows={rows}
          emptyListComponent={
            <div className="px-content alert alert-info">
              {tr("responsesNoData")}
            </div>
          }
          sortChanged={sortOrderChanged}
        />
      </>
    );
  }
);

NewTable.displayName = "NewTable";

export default React.memo(NewTable);
