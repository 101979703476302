export async function copyToClipboard(el: HTMLElement | null) {
  if (!el) {
    console.warn("Attempting to copy, but element not defined");
    return;
  }

  // Generate the table text content
  let text = "";
  const rows = el.querySelectorAll("tr");
  rows.forEach(row => {
    const cells = row.querySelectorAll("th, td");
    const rowText = Array.from(cells)
      .map(cell => (cell as HTMLElement).innerText || (cell as HTMLElement).textContent || "")
      .join("\t");
    text += rowText + "\n";
  });

  try {
    // Modern Clipboard API
    if (navigator.clipboard && navigator.clipboard.writeText) {
      await navigator.clipboard.writeText(text);
      console.log("Copied to clipboard using Clipboard API!");
    } else {
      // Fallback for older browsers (e.g., IE)
      const tempTextArea = document.createElement("textarea");
      tempTextArea.value = text;
      document.body.appendChild(tempTextArea);
      tempTextArea.select();
      try {
        document.execCommand("Copy");
        console.log("Copied to clipboard using execCommand!");
      } catch (err) {
        console.error("Fallback copy failed: ", err);
      } finally {
        document.body.removeChild(tempTextArea);
      }
    }
  } catch (e) {
    console.error("Failed to copy table: ", e);
  }
}
