import React, { ComponentType } from "react";
import { useParams, useLocation, useNavigate, NavigateFunction, Location } from "react-router";

// Define the shape of props injected by withRouter
export interface WithRouterProps {
  navigate: NavigateFunction;
  location: Location;
  params: Record<string, string | undefined>;
}

// Update withRouter to support type inference
const withRouter = <P extends WithRouterProps>(WrappedComponent: ComponentType<P>) => {
  const ComponentWithRouter = (props: Omit<P, keyof WithRouterProps>) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    return (
      <WrappedComponent
        {...(props as P)} // Explicitly typecast props
        navigate={navigate}
        location={location}
        params={params}
      />
    );
  };

  return React.memo(ComponentWithRouter);
};

export default withRouter;
