import gql from "graphql-tag";
import * as _ from "lodash";
import moment from "moment-timezone";
import * as React from "react";
import { MutationFunction, QueryResult } from "@apollo/client";
import { graphql, DataValue } from "@apollo/client/react/hoc";
import { NavigateFunction, RouterProps, useParams } from "react-router";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import {
  AdminContext,
  Translation,
  withAdminContext,
  withTranslation,
} from "../../App/reducer";
import {
  Button,
  Checkbox,
  ConsentForm,
  DatePicker,
  FormInput,
  FormSelect,
  ISTContainer,
  Loading,
} from "../../Common";
import { BottomMenu } from "../../Common/components/BottomMenu";
import { FormSelectTags } from "../../Common/components/FormSelectTags";
import { Time } from "../../Common/components/Time";
import {
  SchoolUnitsFragment,
  formComponentDataFragment,
  fullPublicationFragment,
} from "../../Common/fragments";
import {
  ComplexSet,
  findIdCustomerIdOverlap,
  getCombinedIdCustomerId,
} from "../../Common/utils";
import {
  showAlertDialog,
  showConfirmDialog,
  showErrorDialog,
} from "../../Utils/dialogs/showDialog";
import { definedNotNull, extractDateString } from "../../Utils/functional";
import * as gqltypes from "../../gqltypes";
import {
  defaultDialogCancel,
  defaultDialogProceed,
} from "../../translation/strings";
import { Progress, WithRouteProps } from "../../types";
import { links } from "../links";
import { SchoolUnitTable } from "./SchoolUnitTable";
import withRouter from "../../Utils/withRouter";

/*
const schoolUnitTypes: { value: string; label: string }[] = [
  { value: "FS", label: "Förskola" },
  { value: "FSK", label: "Förskoleklass" },
  { value: "FTH", label: "Fritidshem" },
  { value: "GR", label: "Grundskola" },
  { value: "GRS", label: "Grundsärskola" },
  { value: "SP", label: "Specialskola" },
  { value: "SAM", label: "Sameskola" },
  { value: "GY", label: "Gymnasieskola" },
  { value: "GYS", label: "Gymnasiesärskola" },
  { value: "VUX", label: "Vuxenutbildning" },
  { value: "SUV", label: "Särskild utbildning för vuxna" },
  { value: "YH", label: "Yrkeshögskola" },
  { value: "FHS", label: "Folkhögskola" },
  { value: "HS", label: "Högskola" },
  { value: "AU", label: "Annan undervisning" },
];
*/

type AnswerPeopleType = "all_guardians" | "single_guardian" | "child";

const getAvailableAnswerPeopleOptions = (
  tr: Translation["tr"]
): { value: AnswerPeopleType; label: string }[] => {
  return [
    {
      value: "all_guardians",
      label: tr("createPublicationAnswerPeopleOptionAllGuardians"),
    },
    {
      value: "single_guardian",
      label: tr("createPublicationAnswerPeopleOptionSingleGuardians"),
    },
    {
      value: "child",
      label: tr("createPublicationAnswerPeopleOptionChild"),
    },
  ];
};

const ReplaceablePublicationsQuery = gql`
  query replaceablePublications(
    $formId: ID!
    $minimumValidTo: DateString!
    $timeZoneName: String!
  ) {
    replaceablePublications(
      formId: $formId
      minimumValidTo: $minimumValidTo
      timeZoneName: $timeZoneName
    ) {
      id
      name
      form {
        id
        name
        language
      }
      validTo
    }
  }
`;

const minimumPublicationDelayMinutes = 20;
const currentTimeZoneName = moment.tz.guess();

type Publication =
  gqltypes.replaceablePublications["replaceablePublications"][0];
interface SelectProps extends Translation {
  onSelect: (publication?: Publication) => void;
  selectedPublication?: Publication;
  data: DataValue<gqltypes.replaceablePublications>;
}

interface PreviewProps {
  publication?: DataValue<gqltypes.publication>;
  tr: Translation["tr"];
  formData: DataValue<gqltypes.publishFormData> & QueryResult;
  warning?: string;
  language?: gqltypes.ISO6391Language;
  translation?: gqltypes.FullTranslation | null;
  setState: (v: any) => void;
  selectedForm?: string;
}

function RenderFormPreview(props: PreviewProps) {
  const [response, setResponse] = React.useState<gqltypes.FormAnswer>();

  if (!props.formData.organisation || !props.selectedForm) {
    return null;
  }
  const lockedForm =
    props.publication &&
    props.publication.publication &&
    props.publication.publication.form;

  const forms = _.cloneDeep(props.formData.organisation.sendableForms);
  if (lockedForm && !forms.find((f) => f.id === lockedForm.id)) {
    forms.push(lockedForm);
  }

  const form = forms.find((f) => f.id === props.selectedForm);

  if (!form) {
    props.setState({ selectedForm: undefined });
    return null;
  }

  return (
    <div className="mt-content">
      <h2>{props.tr("createPublicationPreviewTitle")}</h2>
      <p>{props.tr("createPublicationPreviewDescription")}</p>
      <ConsentForm
        predicateComponents={form.componentData.predicateComponents || []}
        formType={form.type}
        response={response}
        updateResponse={(res) => setResponse(res)}
        formOwner={form.owner}
        formTitle={form.name}
        formDescription={form.description}
        components={form.componentData.components as any}
        formLanguage={form.language}
      />
    </div>
  );
}

class SelectPublicationBaseValidity extends React.PureComponent<SelectProps> {
  constructor(props: SelectProps) {
    super(props);
    props.data.refetch();
  }
  public render() {
    const { tr } = this.props;
    const { loading, replaceablePublications } = this.props.data;

    if (loading) {
      return null;
    }
    if (!replaceablePublications) {
      return null;
    }
    const publications = replaceablePublications;
    if (publications.length === 0) {
      return null;
    }

    const options = publications.map((p) => ({
      value: p.id,
      label: tr(
        "createPublicationExtendPublicationRowOption",
        p.name,
        moment(p.validTo).format("YYYY-MM-DD"),
        p.form.name
      ),
    }));

    return (
      <div className="alert-info p-content mb-content">
        <h4>{tr("createPublicationFormAlreadyPublishedTitle")}</h4>
        <p>
          <strong>{tr("createPublicationSetValidityAfterOldFormTitle")}</strong>
          <br />
          {tr("createPublicationSetValidityAfterOldFormDescription")}
        </p>
        <p>
          <strong>{tr("createPublicationExtendPublicationTitle")}</strong>
          <br />
          {tr("createPublicationExtendPublicationDescription")}
          <br />
          {tr("createPublicationExtendPublicationChoosePublication")}
        </p>
        <div>
          <FormSelect
            onChange={this.handleSelect}
            value={
              this.props.selectedPublication
                ? this.props.selectedPublication.id
                : ""
            }
            options={options}
            defaultOption={tr("createPublicationFormNotChosen")}
            label={tr("createPublicationPreviousPublication")}
          />
        </div>
        {this.props.selectedPublication && (
          <p>
            <strong>
              <Link
                to={links.admin.publication.edit(
                  this.props.selectedPublication.id
                )}
              >
                {tr(
                  "createPublicationExtendPublication",
                  this.props.selectedPublication.name
                )}
              </Link>
            </strong>
          </p>
        )}
      </div>
    );
  }

  private handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = event.target.value;
    this.setState({ selected });
    if (selected === "") {
      return this.props.onSelect();
    }
    const publications = this.props.data.replaceablePublications;
    if (!publications) {
      return;
    }
    const selectedPublication = publications.find((p) => p.id === selected);
    if (!selectedPublication) {
      return;
    }
    this.props.onSelect(selectedPublication);
  };
}

const withPublications = graphql<
  {
    minimumValidTo: string;
    formId: string;
    timeZoneName: string;
  } & SelectProps,
  gqltypes.replaceablePublications,
  gqltypes.replaceablePublicationsVariables
>(ReplaceablePublicationsQuery, {
  options: (props) => ({
    variables: {
      formId: props.formId,
      minimumValidTo: props.minimumValidTo,
      timeZoneName: props.timeZoneName,
    },
  }),
});
const SelectPublicationBaseValidityWithData = _.flowRight(
  withTranslation,
  withPublications
)(SelectPublicationBaseValidity as any);

const selectedToList = (selectedStudentGroups: {
  [suid: string]: { id: string; customerId: string }[];
}) => _.flatten(Object.values(selectedStudentGroups));

interface Props extends Response, AdminContext, RouterProps, Translation {
  publication?: DataValue<gqltypes.publication>;
  navigate: NavigateFunction;
}

interface State {
  isEditing: boolean;
  publicationDate?: Date;
  selectedPrecedingPublication?: {
    id: string;
    name: string;
    validTo: string;
    form: {
      id: string;
      name: string;
      language: gqltypes.ISO6391Language;
      __typename: "Form";
    };
    __typename: "Publication";
  };
  validFrom?: Date;
  validTo?: Date;
  selectedForm?: string;
  singleGuardian: boolean;
  childResponse: boolean;
  skipEmails: boolean;
  selectedStudentGroups: {
    [suid: string]: { id: string; customerId: string }[];
  };
  studentGroupYearFilter: { [sgid: string]: number[] | null };
  lockedStudentGroups: { [suid: string]: { id: string; customerId: string }[] };
  lockedStudentGroupYearFilter: { [sgid: string]: number[] | null };
  publicationName: string;

  publishState: Progress;
  selectedSchoolTypeFilter: string;
  schoolUnitNameFilter: string;
  hideSchoolUnitsWithNoClasses: boolean;
  hideEmptyClasses: boolean;
  filteredSchoolUnits: gqltypes.SchoolUnits[] | null;
  classNameFilter: string;
  groupsType: "classDepartment" | "mentor";
  tags: string[];

  showValidation: boolean;
}

class CreatePublication extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);
    let isEditing = false;
    if (definedNotNull(props.publication)) {
      isEditing = true;
    }

    const publicationDate = moment()
      .add(minimumPublicationDelayMinutes, "minutes")
      .toDate();

    const hideSchoolUnitsWithNoClasses = true;
    const hideEmptyClasses = true;
    const groupsType = "classDepartment" as const;

    this.state = {
      isEditing,
      publicationDate,
      validFrom: publicationDate,
      publicationName: "",
      singleGuardian: false,
      childResponse: false,
      skipEmails: false,
      lockedStudentGroups: {},
      selectedStudentGroups: {},
      studentGroupYearFilter: {},
      lockedStudentGroupYearFilter: {},
      publishState: Progress.NotStarted,
      selectedSchoolTypeFilter: "",
      schoolUnitNameFilter: "",
      classNameFilter: "",
      hideSchoolUnitsWithNoClasses,
      hideEmptyClasses,
      groupsType,
      filteredSchoolUnits: this.props.formData.organisation
        ? this.filterSchoolUnits(
            this.props.formData.organisation
              .schoolUnits as gqltypes.SchoolUnits[],
            {
              hideSchoolUnitsWithNoClasses,
              hideEmptyClasses,
              classNameFilter: "",
              schoolUnitNameFilter: "",
              selectedSchoolTypeFilter: "",
              groupsType,
            }
          )
        : null,
      showValidation: false,
      tags: [],
    };
  }

  public setEditedPublicationState = () => {
    const publication =
      this.props.publication && this.props.publication.publication;
    if (!publication) {
      throw new Error("Publication was not defined");
    }
    if (!this.props.formData.organisation) {
      throw new Error("Form data missing");
    }
    const groupIds = publication.recipients.groups.edges.map((edge) => ({
      id: edge.groupId,
      customerId: edge.customerId,
    }));
    const studentGroupYearFilter: State["studentGroupYearFilter"] = {};
    publication.recipients.groups.edges.forEach((sg) => {
      const combinedId = getCombinedIdCustomerId({
        id: sg.groupId,
        customerId: sg.customerId,
      });
      if (sg.schoolYearFilter) {
        studentGroupYearFilter[combinedId] = _.sortBy(sg.schoolYearFilter);
      }
    });

    const selectedStudentGroups: State["selectedStudentGroups"] = {};
    const { schoolUnits } = this.props.formData.organisation;
    for (const schoolUnit of schoolUnits) {
      const locked = findIdCustomerIdOverlap(schoolUnit.classes, groupIds);
      if (locked.length > 0) {
        selectedStudentGroups[getCombinedIdCustomerId(schoolUnit)] = locked;
      }
    }

    const publicationDate = new Date(publication.sendDate);
    const nextState: Partial<State> = {
      validFrom: new Date(publication.validFrom),
      validTo: new Date(publication.validTo),
      publicationName: publication.name,
      publicationDate,
      selectedForm: publication.form.id,
      isEditing: this.state.isEditing || definedNotNull(this.props.publication),
      singleGuardian: publication.singleGuardian,
      childResponse: publication.childResponse,
      skipEmails: publication.skipEmails,
      selectedStudentGroups,
      studentGroupYearFilter,
      tags: publication.tags,
    };

    if (publication.sent) {
      nextState.lockedStudentGroups = selectedStudentGroups;
      nextState.lockedStudentGroupYearFilter = studentGroupYearFilter;
      nextState.selectedStudentGroups = {};
      nextState.studentGroupYearFilter = {};
    }
    this.setState(nextState as State);
  };

  public componentDidMount() {
    if (this.publicationDataLoaded()) {
      this.setEditedPublicationState();
    }
    if (this.props.formData.organisation) {
      this.setState({
        filteredSchoolUnits: _.orderBy(
          this.filterSchoolUnits(
            this.props.formData.organisation
              .schoolUnits as gqltypes.SchoolUnits[],
            {
              classNameFilter: this.state.classNameFilter,
              hideSchoolUnitsWithNoClasses:
                this.state.hideSchoolUnitsWithNoClasses,
              hideEmptyClasses: this.state.hideEmptyClasses,
              schoolUnitNameFilter: this.state.schoolUnitNameFilter,
              selectedSchoolTypeFilter: this.state.selectedSchoolTypeFilter,
              groupsType: this.state.groupsType,
            }
          ),
          "displayName"
        ),
      });
    }
  }

  public filterSchoolUnits = (
    schoolUnits: gqltypes.SchoolUnits[],
    filter: {
      hideSchoolUnitsWithNoClasses: boolean;
      hideEmptyClasses: boolean;
      schoolUnitNameFilter: string;
      classNameFilter: string;
      selectedSchoolTypeFilter: string;
      groupsType: "classDepartment" | "mentor";
    }
  ) => {
    const schoolUnitNameRegex = new RegExp(
      _.escapeRegExp(filter.schoolUnitNameFilter),
      "i"
    );
    const classNameRegex = new RegExp(
      _.escapeRegExp(filter.classNameFilter),
      "i"
    );

    return schoolUnits
      .filter((su) => schoolUnitNameRegex.test(su.displayName))
      .filter(
        (su) =>
          !filter.selectedSchoolTypeFilter ||
          (su.schoolTypes
            ? su.schoolTypes.some(
                (st) => st === filter.selectedSchoolTypeFilter
              )
            : true)
      )
      .filter(
        (su) => !filter.hideSchoolUnitsWithNoClasses || su.classes.length > 0
      )
      .map((su) => {
        return {
          ...su,
          classes: _.orderBy(
            su.classes
              .filter(
                (sg) =>
                  classNameRegex.test(sg.displayName) &&
                  (!filter.hideEmptyClasses || sg.memberCount !== 0)
              )
              .filter((sg) =>
                sg.groupType === "Mentor"
                  ? filter.groupsType === "mentor"
                  : filter.groupsType === "classDepartment"
              ),
            "displayName"
          ),
        };
      })
      .filter((su) => {
        return (
          (!filter.classNameFilter && !filter.hideSchoolUnitsWithNoClasses) ||
          su.classes.length > 0
        );
      });
  };

  public componentDidUpdate(oldProps: Props, oldState: State) {
    const nextState: Partial<State> = {};
    const isEditing =
      this.state.isEditing || definedNotNull(this.props.publication);
    if (isEditing && !this.state.isEditing) {
      nextState.isEditing = true;
    }
    if (this.publicationDataLoaded() && this.props !== oldProps) {
      this.setEditedPublicationState();
    }

    if (
      this.state.schoolUnitNameFilter === oldState.schoolUnitNameFilter &&
      this.state.classNameFilter === oldState.classNameFilter &&
      this.state.selectedSchoolTypeFilter ===
        oldState.selectedSchoolTypeFilter &&
      this.state.hideSchoolUnitsWithNoClasses ===
        oldState.hideSchoolUnitsWithNoClasses &&
      this.state.hideEmptyClasses === oldState.hideEmptyClasses &&
      this.state.groupsType === oldState.groupsType &&
      this.state.selectedForm === oldState.selectedForm
    ) {
      if (this.props.formData.organisation === oldProps.formData.organisation) {
        return;
      }
    }

    if (
      this.props.formData.organisation &&
      this.props.formData.organisation.schoolUnits
    ) {
      const { schoolUnits } = this.props.formData.organisation;

      const filteredSus = this.filterSchoolUnits(
        schoolUnits as gqltypes.SchoolUnits[],
        {
          classNameFilter: this.state.classNameFilter,
          hideSchoolUnitsWithNoClasses: this.state.hideSchoolUnitsWithNoClasses,
          hideEmptyClasses: this.state.hideEmptyClasses,
          schoolUnitNameFilter: this.state.schoolUnitNameFilter,
          selectedSchoolTypeFilter: this.state.selectedSchoolTypeFilter,
          groupsType: this.state.groupsType,
        }
      );

      nextState.filteredSchoolUnits = _.orderBy(filteredSus, "displayName");
    }

    if (Object.keys(nextState).length) {
      this.setState(nextState as any);
    }
  }

  public getAutoValidFrom = () => {
    const publication = this.state.selectedPrecedingPublication;
    if (!publication) {
      return;
    }
    const validToDate = new Date(publication.validTo);
    const validFromDate = moment(validToDate).add(1, "days");
    return validFromDate.toDate();
  };

  public renderValiditySelection = () => {
    const publicationDate = this.state.publicationDate;
    const validFrom = this.state.validFrom;
    const toDateDisabled = validFrom === undefined;
    const autoValidFrom = this.getAutoValidFrom();

    const lockDates =
      this.state.isEditing &&
      this.props.publication &&
      this.props.publication.publication!.sent;

    return (
      <React.Fragment>
        <DatePicker
          id="validFromDate"
          label={this.props.tr("createPublicationValidFrom")}
          disabled={autoValidFrom !== undefined || lockDates}
          containerClass="form-group col-12 col-md-3"
          day={autoValidFrom || validFrom}
          minSelectable={new Date()}
          disabledDays={(day) =>
            publicationDate !== undefined &&
            moment(day).endOf("day").isBefore(publicationDate)
          }
          handleSelectDay={this.handleSelectValidFrom}
          feedback={this.state.showValidation ? this.validateValidFrom() : ""}
        />
        <DatePicker
          id="validToDate"
          label={this.props.tr("createPublicationValidTo")}
          containerClass="form-group col-12 col-md-3"
          day={this.state.validTo}
          minSelectable={new Date()}
          disabled={toDateDisabled || lockDates}
          disabledDays={(day) =>
            validFrom !== undefined && day.getTime() < validFrom.getTime()
          }
          handleSelectDay={this.handleSelectValidTo}
          feedback={
            !toDateDisabled && this.state.showValidation
              ? this.validateValidTo()
              : ""
          }
          tooltipMessage={this.props.tr("formValidToDateTooltip")}
        />
      </React.Fragment>
    );
  };

  private mainRef: any = React.createRef();

  public render() {
    const { tr } = this.props;
    if (this.props.publication && this.props.publication.loading) {
      return <Loading />;
    }
    if (this.props.formData.loading || !this.state.filteredSchoolUnits) {
      return <Loading />;
    }

    const schoolUnits = this.props.formData;
    const formsExist =
      schoolUnits.organisation &&
      schoolUnits.organisation.sendableForms.length > 0;

    const numSelectedSg = selectedToList(
      this.state.selectedStudentGroups
    ).length;

    const lockedForm =
      this.props.publication &&
      this.props.publication.publication &&
      this.props.publication.publication.form;

    const formOptions = schoolUnits.organisation
      ? schoolUnits.organisation.sendableForms.map((form) => ({
          value: form.id,
          label: form.name,
        }))
      : [];

    if (
      lockedForm &&
      !formOptions.find((form) => form.value === lockedForm.id)
    ) {
      formOptions.push({ value: lockedForm.id, label: lockedForm.name });
    }

    const answerPeople = this.state.childResponse
      ? "child"
      : this.state.singleGuardian
      ? "single_guardian"
      : "all_guardians";

    return (
      <main id="create-publication">
        <h1>
          {this.state.isEditing
            ? tr("editPublicationTitle")
            : tr("createPublicationTitle")}
        </h1>
        <p className="col-12 col-md-9 p-0 pb-3">
          {this.state.isEditing ? tr("editPublicationDescription") : ""}
        </p>
        <ISTContainer
          ref={this.mainRef}
          header={
            this.state.isEditing
              ? tr("editPublicationHeader")
              : tr("createPublicationHeader")
          }
        >
          {!formsExist ? (
            <div className="alert alert-info m-content">
              {tr("createPublicationNoFormsToSend")}
            </div>
          ) : (
            <React.Fragment>
              <div className="p-content">
                <div className="row">
                  <FormSelect
                    className="col-12 col-md-4"
                    id="formSelect"
                    label={tr("createFormFormSelectLabel")}
                    disabled={schoolUnits.loading || this.state.isEditing}
                    onChange={this.handleFormChanged}
                    value={this.state.selectedForm || ""}
                    defaultOption={tr("createPublicationFormNotChosen")}
                    options={formOptions}
                    feedback={
                      this.state.showValidation
                        ? this.validateSelectedForm()
                        : undefined
                    }
                    tooltipMessage={this.props.tr(
                      "createPublicationFormTooltipMessage"
                    )}
                  />
                  <FormInput
                    className={"col-12 col-md-4"}
                    label={tr("createFormPublicationNameLabel")}
                    disabled={this.state.isEditing}
                    feedback={
                      this.state.showValidation
                        ? this.validatePublicationName()
                        : ""
                    }
                    id="publication-name-input"
                    onChange={this.handleChangeName}
                    value={this.state.publicationName}
                    tooltipMessage={this.props.tr(
                      "createPublicationPublicationNameTooltipMessage"
                    )}
                  />

                  <DatePicker
                    id="publicationDate"
                    label={tr("createFormSendDateLabel")}
                    containerClass="form-group col-12 col-md-3"
                    disabled={
                      this.state.isEditing &&
                      this.props.publication &&
                      this.props.publication.publication!.sent
                    }
                    day={this.state.publicationDate}
                    handleSelectDay={this.handleSelectPublicationDate}
                    minSelectable={new Date()}
                    feedback={
                      this.state.showValidation
                        ? this.validatePublicationDate()
                        : ""
                    }
                  />
                </div>
                {!this.state.isEditing &&
                  this.state.publicationDate !== undefined &&
                  this.state.selectedForm !== undefined && (
                    <SelectPublicationBaseValidityWithData
                      data={null as any} // Type is just wrong
                      timeZoneName={currentTimeZoneName}
                      minimumValidTo={extractDateString(
                        this.state.publicationDate
                      )}
                      formId={this.state.selectedForm}
                      selectedPublication={
                        this.state.selectedPrecedingPublication
                      }
                      onSelect={this.handleSelectPrecedingPublication}
                    />
                  )}

                <div className="row">
                  {this.renderValiditySelection()}
                  <div className="col-md-5">
                    <FormSelectTags
                      tr={tr}
                      value={this.state.tags}
                      onChange={(tags) => {
                        this.setState({ tags: tags.map((tag) => tag.value) });
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-5">
                    <FormSelect
                      label={tr("createPublicationWhoIsAnswering")}
                      options={getAvailableAnswerPeopleOptions(tr)}
                      value={answerPeople}
                      onChange={(e) => {
                        const { value } = e.currentTarget;
                        if (!value) return;

                        let newState = {
                          singleGuardian: this.state.singleGuardian,
                          childResponse: this.state.childResponse,
                        };

                        switch (value as AnswerPeopleType) {
                          case "all_guardians":
                            newState = {
                              singleGuardian: false,
                              childResponse: false,
                            };
                            break;
                          case "single_guardian":
                            newState = {
                              singleGuardian: true,
                              childResponse: false,
                            };
                            break;
                          case "child":
                            newState = {
                              singleGuardian: false,
                              childResponse: true,
                            };
                            break;
                        }
                        this.setState(newState);
                      }}
                    />
                  </div>

                  <div className="col-12">
                    <Checkbox
                      id="publicationSendEmails"
                      label={tr("createPublicationSendNoEmailsLabel")}
                      checked={this.state.skipEmails}
                      onChange={(id, checked) =>
                        this.setState({
                          skipEmails: checked,
                        })
                      }
                    />

                    <Collapse isOpen={this.state.skipEmails}>
                      <div className="alert alert-warning mt-3" role="alert">
                        {tr("createPublicationSendNoEmailsWarning")}
                      </div>
                    </Collapse>
                  </div>
                </div>

                <hr />

                <div className="row d-flex align-items-end">
                  <FormInput
                    className="col-12 col-md-5"
                    label={tr("createFormFilterOnSchoolUnitName")}
                    id="schoonUnitNameFilter"
                    value={this.state.schoolUnitNameFilter}
                    placeholder={tr(
                      "createFormFilterOnSchoolUnitNamePlaceholder"
                    )}
                    onChange={(event) =>
                      this.setState({
                        schoolUnitNameFilter: event.currentTarget.value,
                      })
                    }
                  />
                  <FormInput
                    className="col-12 col-md-5"
                    label={tr("createFormFilterOnClassName")}
                    id="classNameFilter"
                    value={this.state.classNameFilter}
                    placeholder={tr("createFormFilterOnClassNamePlaceholder")}
                    onChange={(event) =>
                      this.setState({
                        classNameFilter: event.currentTarget.value,
                      })
                    }
                  />
                  {/* <div className="col-12 col-md-5">
                    <div className="form-group">
                      <label htmlFor="schoolunitTypeFilterSelect">
                        Filtera på skoltyp
                      </label>
                      <select
                        className="custom-select"
                        id="schoolunitTypeFilterSelect"
                        value={this.state.selectedSchoolTypeFilter}
                        onChange={event => {
                          this.setState({
                            selectedSchoolTypeFilter: event.currentTarget.value
                          });
                        }}
                      >
                        <option value="">Välj skoltyp...</option>
                        {schoolUnitTypes.map(sut => (
                          <option key={sut.value} value={sut.value}>
                            {sut.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div> */}
                  <div className="col-12 col-md-2">
                    <div className="form-group d-flex justify-content-end">
                      <Button
                        label={tr("clearFilter")}
                        level="secondary"
                        onClick={() =>
                          this.setState({
                            selectedSchoolTypeFilter: "",
                            schoolUnitNameFilter: "",
                            classNameFilter: "",
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-5">
                    <div className="form-group">
                      <Checkbox
                        id="filter"
                        checked={this.state.hideSchoolUnitsWithNoClasses}
                        label={tr("createPublicationHideSchoolunitsNoClasses")}
                        onChange={(id, value) => {
                          this.setState({
                            hideSchoolUnitsWithNoClasses: value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-5">
                    <div className="form-group">
                      <Checkbox
                        id="filterEmptyClasses"
                        checked={this.state.hideEmptyClasses}
                        label={tr("createPublicationHideEmptyGroups")}
                        onChange={(id, value) => {
                          this.setState({
                            hideEmptyClasses: value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div
                    className="btn-group"
                    role="group"
                    aria-label={tr(
                      "createPublicationGroupTypeSelectionAriaLabel"
                    )}
                  >
                    <Button
                      label={tr("createPublicationGroupTypeClassDepartment")}
                      level={
                        this.state.groupsType === "classDepartment"
                          ? "primary"
                          : "secondary"
                      }
                      size="btn-sm"
                      onClick={() => {
                        this.setState({ groupsType: "classDepartment" });
                      }}
                    />
                    <Button
                      label={tr("createPublicationGroupTypeMentor")}
                      level={
                        this.state.groupsType === "mentor"
                          ? "primary"
                          : "secondary"
                      }
                      size="btn-sm"
                      onClick={() => {
                        this.setState({ groupsType: "mentor" });
                      }}
                    />
                  </div>
                </div>
              </div>
              <SchoolUnitTable
                filteredSchoolUnits={this.state.filteredSchoolUnits}
                selectedStudentGroups={this.state.selectedStudentGroups}
                lockedStudentGroups={this.state.lockedStudentGroups}
                addSelectedStudentGroups={this.handleStudentGroupsSelected}
                removeSelectedStudentGroups={
                  this.handleStudentGroupsSelectionRemoved
                }
                studentGroupYearFilters={this.state.studentGroupYearFilter}
                lockedStudentGroupYearFilter={
                  this.state.lockedStudentGroupYearFilter
                }
                changeStudentGroupYearFilter={
                  this.handleStudentGroupYearFilterChanged
                }
                tr={tr}
              />
              <div className="row flex-md-row-reverse p-content clearfix">
                <div className="col-12 col-md-6">
                  {this.state.isEditing
                    ? this.renderSaveButton()
                    : this.renderPublishButton(numSelectedSg)}
                </div>
                <div className="col-12 col-md-6">
                  <span className="">
                    {this.state.publishState === Progress.Error &&
                      tr("createPublicationFailedToPublish")}
                  </span>
                </div>
              </div>
            </React.Fragment>
          )}
          <BottomMenu show={true} alignmentRef={this.mainRef}>
            <div className="p-content clearfix">
              {!this.state.isEditing ? (
                <strong>{tr("yourChangesAreNotSaved")}</strong>
              ) : null}
              {this.state.isEditing
                ? this.renderSaveButton()
                : this.renderPublishButton(numSelectedSg)}
            </div>
          </BottomMenu>
        </ISTContainer>

        <RenderFormPreview
          {...this.props}
          selectedForm={this.state.selectedForm}
          setState={this.setState}
        />
      </main>
    );
  }

  private publicationDataLoaded = () => {
    return (
      this.props.publication &&
      !this.props.publication.loading &&
      this.props.formData &&
      !this.props.formData.loading
    );
  };

  public renderSaveButton = () => {
    return (
      <Button
        onClick={this.save}
        label={this.props.tr("save")}
        className="float-right"
      />
    );
  };

  public renderPublishButton = (numSelectedSg: number) => {
    return (
      <Button
        className="float-right"
        disabled={
          this.state.publishState === Progress.InProgress || !numSelectedSg
        }
        onClick={this.publish}
        label={
          this.state.publishState === Progress.InProgress ? (
            <span>
              <i className="fa fa-spinner fa-spin mr-2" />
              {this.props.tr("creating")}
            </span>
          ) : numSelectedSg ? (
            this.props.tr("createPublicationCreateButtonLabel", numSelectedSg)
          ) : (
            this.props.tr("create")
          )
        }
      />
    );
  };

  private handleSelectPublicationDate = (date: Date | undefined) => {
    if (!date) {
      return this.setState({ publicationDate: undefined });
    }

    const currentTime = new Date();
    const isToday = date.toDateString() === currentTime.toDateString();

    const validFrom = this.state.validFrom;

    const publicationDate = isToday
      ? moment(currentTime)
          .add(minimumPublicationDelayMinutes, "minutes")
          .toDate()
      : date;

    this.setState({
      publicationDate,
      selectedPrecedingPublication: undefined,
    });
    this.handleSelectValidFrom(publicationDate, isToday);
  };

  private handleSelectValidFrom = (
    date: Date | undefined,
    isPublicationDate = false
  ) => {
    if (!date) {
      return this.setState({ validFrom: undefined });
    }

    const isToday = date.toDateString() === new Date().toDateString();

    const exactTime = isPublicationDate
      ? date
      : isToday
      ? this.state.publicationDate
      : undefined;

    const validFrom = exactTime
      ? exactTime
      : moment(date).startOf("day").toDate();
    if (
      this.state.validTo &&
      this.state.validTo.getTime() < validFrom.getTime()
    ) {
      this.setState({ validTo: undefined, validFrom });
    } else {
      this.setState({ validFrom });
    }
  };

  private handleSelectValidTo = (date: Date | undefined) => {
    if (!date) {
      return this.setState({ validTo: undefined });
    }
    const dateEndOfDay = moment(date).endOf("day").toDate();
    this.setState({ validTo: dateEndOfDay });
  };

  private handleFormChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ selectedForm: event.currentTarget.value || undefined });
  };

  private handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ publicationName: event.target.value });
  };

  private handleSelectPrecedingPublication = (
    publication?: gqltypes.replaceablePublications["replaceablePublications"][0]
  ) => {
    const validTo = this.state.validTo;
    const validFrom = this.getAutoValidFrom() || this.state.validFrom;
    if (validTo && validFrom && validTo.getTime() < validFrom.getTime()) {
      this.setState({
        selectedPrecedingPublication: publication,
        validTo: undefined,
      });
    } else {
      this.setState({ selectedPrecedingPublication: publication });
    }
  };

  private handleStudentGroupsSelected = (
    changes: {
      suid: { id: string; customerId: string };
      sgs: { id: string; customerId: string }[];
    }[]
  ) => {
    this.setState((state) => {
      const c = changes.reduce((res, change) => {
        const combined = getCombinedIdCustomerId(change.suid);

        const locked = new ComplexSet(
          getCombinedIdCustomerId,
          state.lockedStudentGroups[combined]
        );
        res[combined] = new ComplexSet(getCombinedIdCustomerId, [
          ...(state.selectedStudentGroups[combined] || []),
          ...change.sgs.filter((sg) => !locked.has(sg)),
        ]).allValues();
        return res;
      }, {} as { [suid: string]: { id: string; customerId: string }[] });
      return {
        selectedStudentGroups: {
          ...state.selectedStudentGroups,
          ...c,
        },
      };
    });
  };

  private handleStudentGroupsSelectionRemoved = (
    changes: {
      suid: { id: string; customerId: string };
      sgs: { id: string; customerId: string }[];
    }[]
  ) => {
    this.setState((state) => {
      const c = changes.reduce((res, change) => {
        const combined = getCombinedIdCustomerId(change.suid);
        const deselected = new ComplexSet(getCombinedIdCustomerId, change.sgs);
        res[combined] = (state.selectedStudentGroups[combined] || []).filter(
          (sg) => !deselected.has(sg)
        );
        return res;
      }, {} as { [suid: string]: { id: string; customerId: string }[] });
      return {
        selectedStudentGroups: {
          ...state.selectedStudentGroups,
          ...c,
        },
      };
    });
  };

  private handleStudentGroupYearFilterChanged = (
    changes: {
      sgid: string;
      customerId: string;
      filter: number[] | null;
    }[]
  ) => {
    const c = changes.reduce((res, change) => {
      res[
        getCombinedIdCustomerId({
          id: change.sgid,
          customerId: change.customerId,
        })
      ] = change.filter ? _.sortBy(change.filter) : change.filter;
      return res;
    }, {} as { [suid: string]: number[] | null });
    this.setState((state) => ({
      studentGroupYearFilter: { ...state.studentGroupYearFilter, ...c },
    }));
  };

  private validatePublicationName = () => {
    if (this.state.publicationName === "") {
      return this.props.tr("validationAnswerCompulsoryButMissing");
    }
  };

  private validatePublicationDate = () => {
    if (this.state.publicationDate === undefined) {
      return this.props.tr("validationSelectDate");
    }
  };

  private validateValidFrom = () => {
    if (this.state.validFrom === undefined) {
      return this.props.tr("validationSelectDate");
    } else if (
      this.state.validFrom !== undefined &&
      this.state.publicationDate !== undefined &&
      this.state.publicationDate.toDateString() !==
        this.state.validFrom.toDateString() &&
      this.state.validFrom.getTime() < this.state.publicationDate.getTime()
    ) {
      return this.props.tr("createPublicationValidFromBeforePublicationData");
    }
  };

  private validateValidTo = () => {
    if (this.state.validTo === undefined) {
      return this.props.tr("validationSelectDate");
    } else if (
      this.state.validTo !== undefined &&
      this.state.validFrom !== undefined &&
      this.state.validTo.getTime() <= this.state.validFrom.getTime()
    ) {
      return this.props.tr("createPublicationValidToBeforeValidFrom");
    }
  };

  private validateSelectedForm = () => {
    if (this.state.selectedForm === undefined) {
      return this.props.tr("createPublicationChooseForm");
    }
  };

  private validateFields = () => {
    return [
      this.validateSelectedForm,
      this.validatePublicationName,
      this.validateValidFrom,
      this.validateValidTo,
    ].every((method) => method() === undefined);
  };

  private confirmationContentDate = (
    publicationDate: Date,
    validTo: Date,
    validFrom: Date
  ) => {
    const fromMoment = moment(validFrom);
    const toMoment = moment(validTo);
    const isToday =
      publicationDate.toDateString() === new Date().toDateString();
    return (
      <React.Fragment>
        <p>
          {isToday ? (
            <span>
              {this.props.tr("createPublicationWillBeSentEarliest1",
                minimumPublicationDelayMinutes
              )}{" "}
              {this.props.tr("createPublicationWillBeSentEarliest2")}
            </span>
          ) : (
            <span>
              {this.props.tr("createPublicationWillBeSentAt")}{" "}
              <strong>
                <Time date={publicationDate} format="date" />
              </strong>
              .
            </span>
          )}
        </p>
        <p>
          {this.props.tr("createPublicationValidityTimeIs")}{" "}
          <strong>{toMoment.diff(fromMoment, "days") + 1}</strong>{" "}
          {this.props.tr("days")}.{" "}
          {this.props.tr("createPublicationValidityTimeAvailability")}{" "}
          <Time date={validFrom} /> - <Time date={validTo} />.
        </p>
      </React.Fragment>
    );
  };

  private confirmPublishContent = (
    numGroups: number,
    publicationDate: Date,
    validTo: Date,
    validFrom: Date
  ) => {
    return (
      <React.Fragment>
        <p>
          {this.props.tr(
            "createPublicationCreatePublicationToNClasses",
            numGroups
          )}
        </p>
        {this.confirmationContentDate(publicationDate, validTo, validFrom)}
      </React.Fragment>
    );
  };

  private publish = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (!this.validateFields()) {
      this.setState({ showValidation: true });
      return showAlertDialog({
        title: this.props.tr("error"),
        message: this.props.tr("createPublicationSomeFieldsIncorrect"),
        proceedText: defaultDialogProceed(this.props.tr),
        cancelText: defaultDialogCancel(this.props.tr),
      });
    }

    const selectedStudentGroups = selectedToList(
      this.state.selectedStudentGroups
    );

    const {
      selectedForm,
      publicationDate,
      publicationName,
      validTo,
      singleGuardian,
      childResponse,
      skipEmails,
      tags,
    } = this.state;
    const validFrom = this.getAutoValidFrom() || (this.state.validFrom as Date);

    if (!publicationDate || !validTo || !selectedForm) {
      throw new Error("Fields not valid -- should not happen.");
    }

    await showConfirmDialog({
      title: this.props.tr("createPublicationConfirmTitle"),
      content: this.confirmPublishContent(
        selectedStudentGroups.length,
        publicationDate,
        validTo,
        validFrom
      ),
      proceedText: this.props.tr("create"),
      cancelText: defaultDialogCancel(this.props.tr),
      proceedFunc: async (hostingComponent) => {
        await this.createPublication({
          hostingComponent,
          publicationName,
          selectedForm,
          selectedStudentGroups,
          validFrom,
          validTo,
          publicationDate,
          singleGuardian,
          childResponse,
          skipEmails,
          tags,
        });
      },
      proceedFuncFooter: (
        <span>
          <i className="fa fa-spinner fa-spin mr-2" />
          {this.props.tr("createPublicationCreatingPublication")}
        </span>
      ),
    });
  };

  private createPublication = async (obj: {
    hostingComponent: { dismiss: () => void };
    publicationName: string;
    selectedForm: string;
    selectedStudentGroups: { id: string; customerId: string }[];
    validFrom: Date;
    validTo: Date;
    publicationDate: Date;
    singleGuardian: boolean;
    childResponse: boolean;
    skipEmails: boolean;
    tags: string[];
  }) => {
    this.setState({ publishState: Progress.InProgress });
    const res = await this.props.publish({
      variables: {
        input: {
          name: obj.publicationName,
          formId: obj.selectedForm,
          recipients: {
            studentGroups: obj.selectedStudentGroups.map((group) => ({
              id: group.id,
              customerId: group.customerId,
              active: true,
              schoolYearFilter:
                this.state.studentGroupYearFilter[
                  getCombinedIdCustomerId(group)
                ],
            })),
          },
          validFrom: extractDateString(obj.validFrom),
          validTo: extractDateString(obj.validTo),
          sendDate: extractDateString(obj.publicationDate),
          timeZoneName: currentTimeZoneName,
          singleGuardian: obj.singleGuardian,
          childResponse: obj.childResponse,
          skipEmails: obj.skipEmails,
          tags: obj.tags,
        },
        context: this.props.adminContext,
      },
    });
    if (!res || res.errors) {
      return this.setState({ publishState: Progress.Error });
    }

    obj.hostingComponent.dismiss();

    await showAlertDialog({
      title: this.props.tr("createPublicationDoneTitle"),
      message: this.props.tr(
        "createPublicationDoneMessage",
        obj.publicationName
      ),
      proceedText: defaultDialogProceed(this.props.tr),
      cancelText: defaultDialogCancel(this.props.tr),
    });
    this.props.navigate(links.admin.publication.list());
  };

  /**
   * Save changes to an already created publication
   */
  private save = async () => {
    if (!this.validateFields()) {
      this.setState({ showValidation: true });
      return showAlertDialog({
        title: this.props.tr("error"),
        message: this.props.tr("createPublicationSomeFieldsIncorrect"),
        proceedText: defaultDialogProceed(this.props.tr),
        cancelText: defaultDialogCancel(this.props.tr),
      });
    }

    const lockedGroups = selectedToList(this.state.lockedStudentGroups);
    const newSelectedGroups = selectedToList(this.state.selectedStudentGroups);
    const allSelectedStudentGroups = [...newSelectedGroups, ...lockedGroups];

    const { selectedForm, publicationDate, validTo } = this.state;
    const validFrom = this.getAutoValidFrom() || (this.state.validFrom as Date);

    if (!publicationDate || !validTo || !selectedForm) {
      throw new Error("Fields not valid -- should not happen.");
    }

    const publication =
      this.props.publication && this.props.publication.publication;

    const isAlreadyPublished = publication ? publication.sent : false;

    const changedSchoolYearFilter = isAlreadyPublished
      ? Object.values(this.state.studentGroupYearFilter).filter(
          (filter) => filter === null || filter.length > 0
        ).length
      : null;

    const numSelectedGroups = allSelectedStudentGroups.length;
    const doSave = await showConfirmDialog({
      title: this.props.tr("createPublicationSavePublicationTitle"),
      content: (
        <React.Fragment>
          <p>
            {isAlreadyPublished
              ? this.props.tr(
                  "createPublicationAdditionalClassesAdded",
                  newSelectedGroups.length
                )
              : this.props.tr(
                  "createPublicationPublicationWillBeSentToClasses",
                  numSelectedGroups
                )}
          </p>
          {changedSchoolYearFilter && changedSchoolYearFilter > 0 ? (
            <p>
              {this.props.tr(
                "createPublicationPublicationChangedYearFilter",
                changedSchoolYearFilter
              )}
            </p>
          ) : null}
          {!isAlreadyPublished
            ? this.confirmationContentDate(publicationDate, validTo, validFrom)
            : ""}
        </React.Fragment>
      ),
      proceedText: this.props.tr("save"),
      cancelText: defaultDialogCancel(this.props.tr),
    });
    if (!doSave) {
      return;
    }

    if (!publication) {
      throw new Error("publication was not defined");
    }

    const initialState = new ComplexSet(
      getCombinedIdCustomerId,
      publication.recipients.groups.edges.map((g) => ({
        id: g.groupId,
        customerId: g.customerId,
      }))
    );

    const newlySelected = allSelectedStudentGroups
      .filter((sg) => !initialState.has(sg))
      .map((g) => ({ ...g, active: true }));

    const selectedSet = new ComplexSet(
      getCombinedIdCustomerId,
      allSelectedStudentGroups
    );

    const unselected = initialState!
      .allValues()
      .filter((sg) => !selectedSet.has(sg))
      .map((g) => ({ ...g, active: false }));

    const changed = Object.entries(this.state.studentGroupYearFilter)
      .map((entry) => {
        const [id, value] = entry;

        const g = publication.recipients.groups.edges.find((group) => {
          return (
            id ===
            getCombinedIdCustomerId({
              id: group.groupId,
              customerId: group.customerId,
            })
          );
        });

        if (!g) return;

        const groupArr = g.schoolYearFilter || [];
        const valueArr = value || [];

        const maxLen = Math.max(groupArr.length, valueArr.length);

        if (maxLen === 0) return;

        const int = _.intersection(groupArr, valueArr);

        return int.length !== maxLen ? g : undefined;
      })
      .filter(definedNotNull)
      .map((g) => ({ id: g.groupId, customerId: g.customerId, active: true }));

    const relevantGroups = _.concat(newlySelected, unselected, changed).map(
      (group) => {
        const combinedGroupId = getCombinedIdCustomerId(group);
        return {
          id: group.id,
          customerId: group.customerId,
          active: group.active,
          schoolYearFilter:
            this.state.studentGroupYearFilter[combinedGroupId] === null
              ? null
              : _.concat(
                  this.state.studentGroupYearFilter[combinedGroupId] || [],
                  this.state.lockedStudentGroupYearFilter[combinedGroupId] || []
                ),
        };
      }
    );

    const input: gqltypes.SavePublicationInput = {
      id: publication.id,
      recipients: {
        studentGroups: relevantGroups,
      },
      tags: this.state.tags,
    };

    if (!isAlreadyPublished) {
      input.validFrom = extractDateString(validFrom);
      input.validTo = extractDateString(validTo);
      input.sendDate = extractDateString(publicationDate);
      input.timeZoneName = currentTimeZoneName;
      input.singleGuardian = this.state.singleGuardian;
      input.childResponse = this.state.childResponse;
    }
    input.skipEmails = this.state.skipEmails;

    try {
      // this.setState({ selectedStudentGroups: {} });
      await this.props.save({
        variables: {
          input,
          context: this.props.adminContext,
        },
      });
      this.props.navigate(
        links.admin.publication.listAfterSave(
          this.props.publication &&
            this.props.publication.publication &&
            this.props.publication.publication.id !== null
            ? this.props.publication.publication.id
            : ""
        )
      );
    } catch (e) {
      showErrorDialog({
        title: this.props.tr("error"),
        message: this.props.tr("failedToSave"),
        proceedText: defaultDialogProceed(this.props.tr),
        cancelText: defaultDialogCancel(this.props.tr),
      });
    } finally {
      this.setEditedPublicationState();
    }
  };
}

interface Response {
  formData: DataValue<gqltypes.publishFormData> & QueryResult;
  publish: MutationFunction<gqltypes.Publish, gqltypes.PublishVariables>;
  save: MutationFunction<gqltypes.Save, gqltypes.SaveVariables>;
}

export const sendableFormsQuery = gql`
  query publishFormData($context: Context!) {
    organisation(context: $context) {
      id
      schoolUnits(filter: { permissionType: send }) {
        ...SchoolUnits
      }
      sendableForms(type: publication) {
        id
        type
        name
        description
        language
        componentData {
          ...FormComponentData
        }
        owner {
          id
          displayName
        }
      }
    }
  }
  ${formComponentDataFragment}
  ${SchoolUnitsFragment}
`;

const withSchoolUnitData = graphql<
  Props,
  gqltypes.publishFormData,
  gqltypes.publishFormDataVariables
>(sendableFormsQuery, {
  name: "formData",
  options: (props) => ({
    variables: {
      context: props.adminContext,
    },
  }),
});

const withSave = graphql(
  gql`
    mutation Save($input: SavePublicationInput!, $context: Context!) {
      savePublication(input: $input, context: $context) {
        ...FullPublication
      }
    }
    ${fullPublicationFragment}
  `,
  { name: "save" }
);

const withPublish = graphql(
  gql`
    mutation Publish($input: CreatePublicationInput!, $context: Context!) {
      createPublication(input: $input, context: $context) {
        ...FullPublication
      }
    }
    ${fullPublicationFragment}
  `,
  { name: "publish" }
);

const withPublication = graphql<
  WithRouteProps<{
    params: { id: string };
  }>,
  gqltypes.publication
>(
  gql`
    query publication($id: ID!) {
      publication(id: $id) {
        ...FullPublication
      }
    }
    ${fullPublicationFragment}
  `,
  {
    name: "publication",
    options: (props) => ({
      variables: {
        id: props.params.id,
      },
    }),
  }
);

export const CreatePublicationContainer = _.flowRight(
  withTranslation,
  withAdminContext,
  withSchoolUnitData,
  withPublish,
  withRouter
)(CreatePublication);

export const EditPublicationContainer = _.flowRight(
  withTranslation,
  withRouter,
  withPublication,
  withAdminContext,
  withSchoolUnitData,
  withSave
)(CreatePublication);
