import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { Translation, withTranslation } from "../../App/reducer";
import { definedNotNull } from "../../Utils/functional";
import { Button } from "..";
import * as gqltypes from "../../gqltypes";

function toArray<T>(item: T | T[] | undefined) {
  if (!item) {
    return [];
  }

  if (Array.isArray(item)) {
    return item;
  }

  return [item];
}

const rowKeyUpHandler =
  (onClick?: () => void) =>
  (event: React.KeyboardEvent<HTMLTableRowElement>) => {
    if (!onClick) return;

    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      onClick!();
      return true;
    }
  };

function stopPropagation(event: React.BaseSyntheticEvent) {
  event.stopPropagation();
}

export interface TableColumnData {
  content: React.ReactNode;
  href?: string;
  sortValue?: any;
  className?: string;
  onClick?: () => void;
  preventsClick?: boolean;
  style?: React.CSSProperties;
}

export interface TableHeader {
  key: string;
  element: React.ReactNode;
  name?: string;
  usingSortValue?: boolean;
  unsortable?: boolean;
  hidden?: boolean;
  style?: React.CSSProperties;
  sortArrowOwnLine?: boolean;
  className?: string;
}

export interface TableTopHeader extends TableHeader {
  ref: string[];
}

export interface TableRow {
  key: string | number;
  columns: {
    [key: string]: TableColumnData;
  };
  onClick?: () => void;
  selected?: boolean;
  className?: string;
  hidden?: boolean;
}

type OrderDir = "asc" | "desc";

export interface TableSortOrder {
  col: string;
  dir: OrderDir;
}

interface Props extends Translation {
  topHeaders?: TableTopHeader[];
  headers: TableHeader[];
  rows: TableRow[];
  wrapperClassName?: string;
  initialOrder?: string | string[];
  initialOrderDir?: OrderDir | OrderDir[];
  limit?: number;
  overrideLimit?: boolean;
  onOverrideLimitClick?: () => void;
  noEndLine?: boolean;
  clickableRows?: boolean;
  emptyListComponent?: React.ReactNode;
  unsortable?: boolean;
  sortChanged?: (sortOrder: TableSortOrder[]) => void;
  noRowsMessage?: string;
  thClassName?: string;
  tableRef?: React.RefObject<HTMLTableElement>;
}

interface State {
  order: TableSortOrder[];
}

class TableInner extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const orderDir = toArray(props.initialOrderDir);
    const order = toArray(props.initialOrder).map((sortKey, i) => {
      const dir = orderDir[i] || "desc";
      return { col: sortKey, dir };
    });
    this.state = {
      order,
    };
  }
  public render() {
    const {
      tr,
      limit,
      overrideLimit,
      noEndLine,
      headers,
      onOverrideLimitClick,
      noRowsMessage,
      tableRef,
    } = this.props;
    const fixedOrderBy = [...this.state.order].map((item) => {
      const header = headers.find((h) => h.key === item.col);
      if (header) {
        const prefix = "columns." + item.col;
        return prefix + (header.usingSortValue ? ".sortValue" : ".content");
      } else {
        console.error(
          `Table: Cannot find column name "${item.col}" to sort on`
        );
        return item.col;
      }
    });
    const filteredSortedRows = (
      _.orderBy(
        this.props.rows,
        fixedOrderBy,
        this.state.order.map((order) => order.dir)
      ) as TableRow[]
    ).filter((row, i) => (limit ? i < limit || overrideLimit : true));
    const limited = filteredSortedRows.length !== this.props.rows.length;
    const classNames = `table ${
      filteredSortedRows.length > 10 ? "table-sticky-header" : ""
    } ${noEndLine ? "table-no-end-line" : ""} mb-0`;
    const orderHead = _.head(this.state.order);
    const filteredHeaders = headers.filter((header) => !header.hidden);
    let currentDisplacement = 0;
    const thClassName = this.props.thClassName
      ? " " + this.props.thClassName
      : "";

    return (
      <div
        className={`my-content ${
          this.props.initialOrder === "schoolUnitName" ? "" : "table-responsive"
        } ${this.props.wrapperClassName ? this.props.wrapperClassName : ""}`}
        style={
          this.props.initialOrder === "schoolUnitName"
            ? { maxHeight: "80vh" }
            : {}
        }
      >
        <table
          ref={tableRef}
          className={classNames}
          style={
            this.props.initialOrder === "schoolUnitName"
              ? { minWidth: "1080px" }
              : {}
          }
        >
          <thead>
            {this.props.topHeaders && (
              <tr>
                {this.props.topHeaders.map((topHeader) => {
                  const displayedRefs = topHeader.ref
                    .map((ref) => headers.find((header) => header.key === ref))
                    .filter(definedNotNull)
                    .filter((col) => !col.hidden);
                  const width = displayedRefs.length;
                  if (!width) {
                    return null;
                  }
                  const wantedPos = filteredHeaders.findIndex(
                    (header) => header.key === displayedRefs[0].key
                  );
                  if (wantedPos === -1) {
                    throw new Error("pos not found for key " + topHeader.ref);
                  }
                  const spacing = wantedPos - currentDisplacement;
                  currentDisplacement += width + spacing;
                  return (
                    <React.Fragment key={topHeader.key}>
                      {spacing > 0 && <th colSpan={spacing} />}
                      <th
                        colSpan={width}
                        className={topHeader.className + thClassName}
                      >
                        {topHeader.element}
                      </th>
                    </React.Fragment>
                  );
                })}
              </tr>
            )}
            <tr>
              {filteredHeaders.map((header) => {
                const isFirstOrder = orderHead && orderHead.col === header.key;
                const orderDirectionIcon =
                  orderHead && isFirstOrder ? (
                    <i
                      className={`ml-1 fas fa-${
                        orderHead.dir === "asc" ? "caret-up" : "caret-down"
                      }`}
                    />
                  ) : this.props.initialOrder === "schoolUnitName" ? (
                    ""
                  ) : (
                    <i className="ml-1 fas fa-caret-down invisible" />
                  );
                const colName = header.name || header.element;
                const isUnsortable = header.unsortable || this.props.unsortable;
                return (
                  <th
                    scope="col"
                    key={header.key}
                    style={header.style}
                    aria-label={
                      isUnsortable
                        ? undefined
                        : tr("tableAriaOrderByColName", colName)
                    }
                    onClick={
                      isUnsortable ? undefined : () => this.orderBy(header.key)
                    }
                    className={`${
                      isUnsortable ? "" : "clickable"
                    } align-top noselect ${
                      header.className || ""
                    }${thClassName} ${
                      this.props.initialOrder === "schoolUnitName"
                        ? header.key === gqltypes.PermissionType.admin
                          ? "th-border-bottom"
                          : "th-border"
                        : ""
                    }`}
                  >
                    <>
                      {header.element}
                      {header.sortArrowOwnLine ? <br /> : null}
                      {!isUnsortable && orderDirectionIcon}
                    </>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody
            className={`${this.props.clickableRows ? "clickable-rows" : ""}`}
          >
            {filteredSortedRows.map((row) => {
              if (row.hidden) {
                return null;
              }
              return (
                <tr
                  tabIndex={row.onClick ? 0 : undefined}
                  key={row.key}
                  onKeyUp={rowKeyUpHandler(row.onClick)}
                  onClick={row.onClick}
                  className={`${row.onClick ? "clickable" : ""} ${
                    row.selected ? "selected" : ""
                  } ${row.className || ""}`}
                >
                  {filteredHeaders.map((col) => {
                    const rowData = row.columns[col.key];
                    if (!rowData) {
                      throw new Error(`Table missing key ${col.key}`);
                    }
                    return (
                      <td
                        style={rowData.style}
                        key={col.key}
                        className={`${rowData.className || ""} align-middle ${
                          rowData.preventsClick ? "not-clickable" : ""
                        } ${
                          this.props.initialOrder === "schoolUnitName"
                            ? col.key === gqltypes.PermissionType.admin
                              ? "td-border-bottom"
                              : "td-border"
                            : ""
                        }`}
                        onClick={
                          rowData.preventsClick ? stopPropagation : undefined
                        }
                      >
                        {rowData.href ? (
                          <Link to={rowData.href}>{rowData.content}</Link>
                        ) : (
                          rowData.content
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {limit && (limited || overrideLimit) && (
          <div className="content my-content">
            <div className="row justify-content-center">
              <Button
                aria-expanded={overrideLimit}
                label={
                  overrideLimit
                    ? tr("tableHideRows")
                    : tr("tableShowAll", this.props.rows.length)
                }
                size="btn-sm"
                level="secondary"
                onClick={onOverrideLimitClick}
              />
            </div>
          </div>
        )}
        {filteredSortedRows.length === 0 &&
          (this.props.emptyListComponent || (
            <div className="px-content">
              {noRowsMessage ? noRowsMessage : tr("tableNoRows")}
            </div>
          ))}
      </div>
    );
  }
  private orderBy = (key: string) => {
    const newOrder = _.cloneDeep(this.state.order);
    const first = _.head(newOrder);
    // If first, just flip direction
    if (first && first.col === key) {
      first.dir = first.dir === "asc" ? "desc" : "asc";
    }
    // already in sort order, move first
    else if (newOrder.find((item) => item.col === key)) {
      const existing = _.remove(newOrder, (item) => item.col === key);
      if (existing.length === 1) {
        const item = existing[0];
        newOrder.unshift(item);
      } else {
        console.error("Table orderBy: Found many existing");
      }
    }
    // does not already exist, place first with some default order
    else {
      newOrder.unshift({ col: key, dir: "desc" });
    }
    this.setState({ order: newOrder });
    if (this.props.sortChanged) {
      this.props.sortChanged(newOrder);
    }
  };
}

export const Table = withTranslation(TableInner);
