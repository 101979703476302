import * as React from "react";

export type ButtonLevel =
  | "primary"
  | "secondary"
  | "danger"
  | "success"
  | "warning"
  | "info"
  | "light"
  | "dark"
  | "link";

interface Props {
  id?: string;
  tabIndex?: number;
  className?: string;
  icon?: React.ReactNode;
  backIcon?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
  type?: "button" | "submit";
  label: string | JSX.Element;
  title?: string;
  level?: ButtonLevel;
  disabled?: boolean;
  size?: "btn-sm" | "btn-lg";
  autoFocus?: boolean;
  style?: React.CSSProperties;
}

export function Button(props: Props) {
  const level = props.level || "primary";
  const type = props.type || "button";
  const className = props.className ? ` ${props.className}` : "";
  const size = props.size ? " " + props.size : "";
  const classes = ["btn btn-" + level, className, size].join(" ");

  const { label, disabled, onClick, onFocus, autoFocus, tabIndex, style } = props;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!disabled && onClick) {
      onClick(event);
    }
    event.currentTarget.blur();
  };

  return (
    <button
      id={props.id}
      title={props.title}
      ref={(r) => autoFocus && r && r!.focus()}
      className={classes}
      onClick={handleClick}
      onFocus={!disabled ? onFocus : undefined}
      type={type}
      tabIndex={tabIndex}
      disabled={disabled}
      autoFocus={autoFocus}
      style={style}
    >
      {props.icon && <span className="mr-2">{props.icon}</span>}
      {label}
      {props.backIcon && <span className="ml-2">{props.backIcon}</span>}
    </button>
  );
}