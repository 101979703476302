import { connect, useSelector } from "react-redux";
import { ReducerBuilder, merge } from "redux-ts-simple";
import { ISO6391Language } from "../gqltypes";
import { UserPermissions } from "../permissions";
import { StoreState } from "../store";
import { getTr } from "../translation";
import { OIDCUser } from "../types";
import * as actions from "./actions";
import { selectAdminContext } from "../Utils/selector";

export interface Context {
  org: string | null;
  language: string;
}

interface UserIdpProfile {
  sub: string;
  name: string;
  email: string | null;
}

export interface UserProfile {
  actorId: AppState["actorId"];
  user: UserIdpProfile | undefined;
  educloudUserIds: AppState["educloudUserIds"];
}

export interface Translation {
  uiLanguage: string;
  tr: ReturnType<typeof getTr>;
}

export interface AdminContext {
  adminContext: Context;
}

export interface AvailableOrg {
  id: string;
  displayName: string;
  defaultLanguage: ISO6391Language;
  features?: {
    relocation: boolean;
  };
  account?: { sub: string; org: string; orgName: string };
}

export interface CurrentOrg {
  org: AvailableOrg | null;
  impersonation: ImpersonationInfo | null;
}

export interface UserPermission {
  permissions: AppState["permissions"];
}

interface ImpersonationInfo {
  sub: string;
  org: string;
}

export interface AppState {
  isAuthenticating: boolean;
  user: OIDCUser | null;
  isInitialized: boolean;
  hasMinimizedContactInfoAlert: boolean;
  cookiesUnavailable: boolean;
  timeSkewError: boolean;

  userInfoLoaded: boolean;
  uiLanguage: string;
  idp: string;

  context: Context;
  impersonate: ImpersonationInfo | null;

  actorId: string | null;
  educloudUserIds: { id: string; customerId: string }[] | null;
  requestedAdminView: boolean;
  availableOrgs: AvailableOrg[] | null;
  orgSelected: boolean | null;
  permissions: UserPermissions | null;

  fatalError: string | null;
  error: {
    title: string;
    message?: string;
  } | null;

  // demo things
  skipPermissionCheck?: boolean;
}

const initialState: AppState = {
  isAuthenticating: false,
  user: null,
  isInitialized: false,
  hasMinimizedContactInfoAlert: false,
  cookiesUnavailable: false,
  timeSkewError: false,

  userInfoLoaded: false,
  uiLanguage: "en",
  idp: "",

  context: {
    org: null,
    language: "en",
  },
  impersonate: null,

  actorId: null,
  educloudUserIds: null,
  availableOrgs: null,
  orgSelected: null,
  requestedAdminView: false,
  permissions: null,

  fatalError: null,
  error: null,
};

export const withUserProfile = (component: any) =>
  connect(
    (
      state: StoreState
    ): {
      actorId: string | null;
      user: OIDCUser["profile"] | undefined;
      educloudUserIds: { id: string; customerId: string }[] | null;
    } => ({
      actorId: state.app.actorId,
      user: state.app.user ? state.app.user.profile : undefined,
      educloudUserIds: state.app.educloudUserIds,
    })
  )(component);

export const useUserProfile = () => {
  return useSelector<
    StoreState,
    {
      actorId: string | null;
      user: OIDCUser["profile"] | undefined;
      educloudUserIds: { id: string; customerId: string }[] | null;
    }
  >((state) => ({
    actorId: state.app.actorId,
    user: state.app.user ? state.app.user.profile : undefined,
    educloudUserIds: state.app.educloudUserIds,
  }));
};

export const withCurrentOrg = (component: any) =>
  connect(
    (
      state: StoreState
    ): {
      org: AvailableOrg | null;
    } => ({
      org:
        state.app.availableOrgs?.find(
          (org) => org.id === state.app.context.org
        ) || null,
    })
  )(component);

export const useCurrentOrg = () => {
  return useSelector<
    StoreState,
    { org: AvailableOrg | null; impersonate: ImpersonationInfo | null }
  >((state) => ({
    org:
      (state.app.impersonate
        ? state.app.availableOrgs?.find(
            (org) =>
              org.id === state.app.context.org &&
              state.app.impersonate?.sub === org.account?.sub
          )
        : state.app.availableOrgs?.find(
            (org) => org.id === state.app.context.org
          )) || null,
    impersonate: state.app.impersonate,
  }));
};

export const withUiLanguage = (component: any) =>
  connect(
    (state: StoreState): Pick<AppState, "uiLanguage"> => ({
      uiLanguage: state.app.uiLanguage,
    })
  )(component);

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

export const withTranslation = <P extends Record<string, any>>(
  component: React.ComponentType<P>
): React.ComponentType<Partial<{ [x: string]: any }>> =>
  connect<Translation, {}, any, StoreState>((state) => ({
    uiLanguage: state.app.uiLanguage,
    tr: getTr(state.app.uiLanguage),
  }))(component as any);

export const useTranslation = () => {
  const uiLanguage = useSelector<StoreState, string>(
    (state) => state.app.uiLanguage
  );

  return {
    uiLanguage,
    tr: getTr(uiLanguage),
  };
};

export const withUserPermissions = (component: any) =>
  connect(
    (state: StoreState): Pick<AppState, "permissions"> => ({
      permissions: state.app.permissions,
    })
  )(component);

  export const withAdminContext = <T>(component: any): React.ComponentType<any> =>
    connect<AdminContext, {}, any, StoreState>((state) => ({
      adminContext: selectAdminContext(state),
    }))(component);
  
  export const useAdminContext = () => {
    const adminContext = useSelector(selectAdminContext);
    return adminContext;
  };

export const appReducer = new ReducerBuilder(initialState)
  .on(actions.userChanged, (state, action) =>
    merge(state, {
      ...{
        availableOrgs: initialState.availableOrgs,
        permissions: initialState.permissions,
        educloudUserIds: initialState.educloudUserIds,
      },
      context: { ...state.context, org: null },
      user: action.payload,
    })
  )
  .on(actions.toggleMinimizeContactInfoAlert, (state) =>
    merge(state, {
      hasMinimizedContactInfoAlert: !state.hasMinimizedContactInfoAlert,
    })
  )
  .on(actions.flagCookiesUnavailable, (state) =>
    merge(state, {
      cookiesUnavailable: true,
    })
  )
  .on(actions.flagTimeSkewError, (state) =>
    merge(state, {
      timeSkewError: true,
    })
  )
  .on(actions.requestedAdminView, (state) =>
    merge(state, { requestedAdminView: true })
  )
  .on(actions.userInfoChanged, (state, action) =>
    merge(state, {
      educloudUserIds: action.payload.educloudUserIds,
      actorId: action.payload.id,
      userInfoLoaded: true,
    })
  )
  .on(actions.availableOrgsChanged, (state, action) =>
    merge(state, { availableOrgs: action.payload })
  )
  .on(
    actions.orgWillChange,
    (state, action) =>
      // Do nothign
      state
  )
  .on(actions.orgChanged, (state, action) =>
    merge(state, {
      context: { ...state.context, org: action.payload.id },
      impersonate: action.payload.account || null,
    })
  )
  .on(actions.orgSelected, (state, action) =>
    merge(state, {
      orgSelected: action.payload,
    })
  )
  .on(actions.permissionsChanged, (state, action) =>
    merge(state, { permissions: action.payload })
  )
  .on(actions.changeUiLanguage, (state, action) =>
    merge(state, {
      uiLanguage: action.payload,
      context: { ...state.context, language: action.payload },
    })
  )
  .on(actions.changeIdp, (state, action) =>
    merge(state, { idp: action.payload })
  )
  .on(actions.initialized, (state) => merge(state, { isInitialized: true }))
  .on(actions.loginRequested, (state) =>
    merge(state, { isAuthenticating: true })
  )
  .on(actions.fatalError, (state, action) =>
    merge(state, { fatalError: action.payload })
  )
  .on(actions.errorSplash, (state, action) =>
    merge(state, { error: action.payload })
  )
  .on(actions.toggleSkipPermissionCheck, (state) =>
    merge(state, { skipPermissionCheck: !state.skipPermissionCheck })
  )
  .build();
