import * as React from "react";
import {
  classNames,
  definedNotNull,
  nonEmptyString,
} from "../../Utils/functional";
import { HelpTooltip } from "./HelpTooltip";

export type FormInputType =
  | "text"
  | "number"
  | "email"
  | "password"
  | "tel"
  | "search";

interface Props {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  id: string;
  feedback?: string;
  label?: React.ReactNode;
  labelTitleText?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  type?: FormInputType;
  value: string | number;
  subText?: string;
  tooltipMessage?: string;
}

export class FormInput extends React.Component<Props, never> {
  private input: React.RefObject<HTMLInputElement>;
  constructor(props: Props) {
    super(props);
    this.input = React.createRef();
  }

  public focus = () => {
    if (this.input.current) {
      this.input.current.focus();
    }
  };

  public render() {
    const {
      className = "",
      feedback,
      label,
      tooltipMessage,
      labelTitleText,
      type = "text",
      subText,
      ...attributes
    } = this.props;
    const invalidMessage = feedback
      ? feedback
      : this.input.current
      ? this.input.current.validationMessage
      : undefined;
    const labelClasses = classNames({
      "form-input-label": true,
      "is-invalid": nonEmptyString(feedback),
      "has-title": definedNotNull(labelTitleText),
    });
    const inputClasses = classNames({
      "is-invalid": nonEmptyString(feedback),
      "form-control": true,
    });
    return (
      <div className={"form-group " + className}>
        {label && (
          <label
            className={`${labelClasses} mr-1`}
            title={labelTitleText}
            htmlFor={attributes.id}
          >
            {label}
          </label>
        )}
        {tooltipMessage && <HelpTooltip tooltipText={tooltipMessage} />}
        <input
          {...attributes}
          aria-describedby={feedback ? attributes.id + "_error" : undefined}
          aria-invalid={feedback ? true : false}
          name={attributes.id}
          className={inputClasses}
          type={type}
          ref={this.input}
        />
        {feedback ? (
          <div id={attributes.id + "_error"} className="invalid-feedback">
            {invalidMessage}
          </div>
        ) : null}
        {subText && <span className="text-muted">{subText}</span>}
      </div>
    );
  }
}
