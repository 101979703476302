import _ from "lodash";
import * as React from "react";
import { QueryResult } from "@apollo/client";
import { graphql, DataValue } from "@apollo/client/react/hoc";
import {
  UserProfile,
  withUiLanguage,
  withUserProfile,
} from "../../App/reducer";
import { ISTContainer, Loading, Table } from "../../Common";
import { ErrorBox } from "../../Common/components/ErrorBox";
import { Time } from "../../Common/components/Time";
import { getApplicationStatusLabel } from "../../Common/utils";
import { definedNotNull } from "../../Utils/functional";
import * as gqltypes from "../../gqltypes";
import { getTr } from "../../translation";
import { FORM_DATA_QUERY } from "../queries";
import { FormCardContainer } from "./FormCard";
import { NavigateFunction } from "react-router";
import withRouter, { WithRouterProps } from "../../Utils/withRouter";

const ResponseStatus = gqltypes.PublicationResponseStatus;

interface Props extends UserProfile, WithRouterProps {
  uiLanguage: string;
  formData: DataValue<gqltypes.GuardianFormList> & QueryResult;
  navigate: NavigateFunction;
}

class Guardian extends React.Component<Props, never> {
  public render() {
    const tr = getTr(this.props.uiLanguage);
    if (this.props.formData.loading && !this.props.formData.me) {
      return <Loading />;
    }

    if (this.props.formData.error || !this.props.formData.me) {
      const networkError =
        this.props.formData.error && this.props.formData.error.networkError;

      const status =
        networkError &&
        (networkError as any).response &&
        (networkError as any).response.status;
      const isServerError = status >= 500 && status <= 599;
      return (
        <ErrorBox
          title={
            isServerError
              ? tr("guardianServiceProblems")
              : tr("guardianGenericWrong")
          }
          message={
            isServerError
              ? tr("guardianTryLater")
              : tr("guardianReloadTryAgain")
          }
        />
      );
    }

    const { withValidResponse, withoutValidResponse } =
      this.getDataPerPublication();

    const applicationResponses = this.getApplicationsData();

    const missingEmail =
      this.props.formData.me && this.props.formData.me.emails
        ? !this.props.formData.me.emails.some(
            (email) => email.source === gqltypes.EmailSource.skolid
          )
        : false;

    return (
      <div id="guardian">
        {/* {missingEmail && <ContactInfoAlert />} */}
        <h1>{tr("guardianFormsTitle")}</h1>
        <p className="col-12 col-md-9 p-0 pb-3">
          {tr("guardianFormsDescription")}
        </p>
        <ISTContainer header={tr("guardianUnansweredForms")}>
          <div className="p-content">
            {withoutValidResponse.length === 0 ? (
              <div className="alert">
                {tr("guardianUnansweredFormsUnavailable")}
              </div>
            ) : (
              <div className="row no-gutters">
                {withoutValidResponse.map((data) => {
                  return (
                    <FormCardContainer
                      currentUserId={this.props.user && this.props.actorId}
                      key={data.recipient.id}
                      recipient={data.recipient}
                      child={data.child}
                      myEducloudUserIds={this.props.educloudUserIds}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </ISTContainer>
        <ISTContainer header={tr("guardianAnsweredForms")}>
          <div className="p-content">
            {withValidResponse.length === 0 ? (
              <div className="alert">
                {tr("guardianAnsweredFormsUnavailable")}
              </div>
            ) : (
              <div className="row no-gutters">
                {withValidResponse.map((data) => {
                  return (
                    <FormCardContainer
                      currentUserId={this.props.user && this.props.actorId}
                      key={data.recipient.id}
                      recipient={data.recipient}
                      child={data.child}
                      myEducloudUserIds={this.props.educloudUserIds}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </ISTContainer>
        <ISTContainer header={tr("guardianApplications")}>
          {applicationResponses.length === 0 ? (
            <div className="p-content alert">
              {tr("guardianApplicationsUnavailable")}
            </div>
          ) : (
            <div className="">
              <Table
                initialOrder="created"
                initialOrderDir="desc"
                headers={[
                  {
                    key: "person",
                    element: tr("guardianApplicationPerson"),
                  },
                  {
                    key: "form",
                    element: tr("guardianApplicationForm"),
                  },
                  {
                    key: "status",
                    element: tr("guardianApplicationStatus"),
                    unsortable: true,
                  },
                  {
                    key: "created",
                    element: tr("guardianApplicationCreated"),
                    usingSortValue: true,
                  },
                ]}
                rows={applicationResponses.map((appRes) => ({
                  key: appRes.id,
                  onClick: () => {
                    this.props.navigate(
                      `/application/${appRes.application.id}/${appRes.id}`
                    );
                  },
                  columns: {
                    person: {
                      content: appRes.subject.name,
                    },
                    form: {
                      content: appRes.application.form.name,
                    },
                    status: {
                      content: getApplicationStatusLabel(tr, appRes.status),
                    },
                    created: {
                      content: <Time date={appRes.created} />,
                      sortValue: appRes.created,
                    },
                  },
                }))}
              />
            </div>
          )}
        </ISTContainer>
      </div>
    );
  }

  private getDataPerPublication = () => {
    if (!this.props.formData.me || !this.props.formData.me.relations) {
      throw new Error("");
    }
    const connectedUsers = this.props.formData.me.connectedUsers;
    const relations = this.props.formData.me.relations.edges
      .map((e) => e.node)
      .filter(definedNotNull);
    const items = _.flatten(
      relations.map((r) =>
        r.publicationRecipients!.map((p) => ({
          recipient: p,
          child: r,
          responses: p.responses,
        }))
      )
    ).concat(
      _.flatten(
        connectedUsers
          ? connectedUsers.map((u) =>
              u.publicationRecipients!.map((p) => ({
                recipient: p,
                child: u,
                responses: p.responses,
              }))
            )
          : []
      )
    );
    return {
      withValidResponse: _.orderBy(
        items.filter((item) =>
          item.responses.some((r) => r.status === ResponseStatus.valid)
        ),
        "recipient.created"
      ) as {
        recipient: gqltypes.GuardianFormList_me_relations_edges_node_publicationRecipients;
        child: gqltypes.GuardianFormList_me_relations_edges_node;
        responses: gqltypes.GuardianFormList_me_relations_edges_node_publicationRecipients_responses[];
      }[],
      withoutValidResponse: _.orderBy(
        items.filter(
          (item) =>
            !item.responses.some((r) => r.status === ResponseStatus.valid)
        ),
        "recipient.created"
      ) as {
        recipient: gqltypes.GuardianFormList_me_relations_edges_node_publicationRecipients;
        child: gqltypes.GuardianFormList_me_relations_edges_node;
        responses: gqltypes.GuardianFormList_me_relations_edges_node_publicationRecipients_responses[];
      }[],
    };
  };

  private getApplicationsData = () => {
    const me = this.props.formData.me;
    if (!me || !me.relations) {
      throw new Error("");
    }
    const connectedUsers = me.connectedUsers;
    const relations = me.relations.edges
      .map((e) => e.node)
      .filter(definedNotNull);
    const items = _.flatten(relations.map((r) => r.applicationResponses))
      .concat(
        _.flatten(
          connectedUsers
            ? connectedUsers.map((u) => u.applicationResponses)
            : []
        )
      )
      .concat(me.applicationResponses);

    const distinctItems = new Map<string, (typeof items)[0]>();
    items.forEach((item) => {
      if (!distinctItems.has(item.id)) {
        distinctItems.set(item.id, item);
      }
    });

    return [...distinctItems.values()];
  };
}

const withFormsData = graphql<Props, gqltypes.GuardianFormList>(
  FORM_DATA_QUERY,
  {
    name: "formData",
    options: {
      fetchPolicy: "cache-and-network",
    },
  }
);

export const GuardianContainer = withUiLanguage(
  withUserProfile(withFormsData(withRouter(Guardian)))
);
