import gql from "graphql-tag";
import _ from "lodash";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useQuery } from "@apollo/client";
import { connect } from "react-redux";
import { Location, NavigateFunction, Params } from "react-router";
import {
  AdminContext,
  Translation,
  withAdminContext,
  withTranslation,
} from "../../App/reducer";
import { Checkbox, ISTContainer, Loading, TableSortOrder } from "../../Common";
import { formComponentDataFragment } from "../../Common/fragments";

import { permissionResourceIdFromSchoolUnit } from "../../Common/utils";
import * as f from "../../Utils/functional";
import { assertUnreachable } from "../../Utils/typeHelpers";
import * as gqltypes from "../../gqltypes";
import { UserPermissions } from "../../permissions";
import * as persistance from "../../persistance";
import { StoreState } from "../../store";

import { links } from "../links";
import {
  allPredicateQuestions,
  allPredicateQuestionsAndComponents,
} from "./CreateForm";
import withRouter from "../../Utils/withRouter";
import FormSelectGroupedCategories from "../../Common/components/FormSelectGroupedCategories";
import NewTable from "./NewTable";
import { ClipboardCopyButton } from "../../Common/components/ClipboardCopyButton";
import Tooltip from "reactstrap/es/Tooltip";

const STUDENT_GROUP_FORMS_QUERY = gql`
  query studentGroupForms(
    $groupId: ID!
    $customerId: ID!
    $context: Context!
    $expired: Boolean
  ) {
    studentGroup(
      id: $groupId
      customerId: $customerId
      context: $context
      expired: $expired
    ) {
      id
      customerId
      displayName
      publications(filter: valid, expired: $expired) {
        id
        name
        sendDate
        validTo
        form {
          id
          name
          componentData {
            ...FormComponentData
          }
        }
      }
    }
  }
  ${formComponentDataFragment}
`;

const getExtraColumnLabel = (tr: Translation["tr"], id: Extras) => {
  switch (id) {
    case "date":
      return tr("responsesExtraDateLabel");
    case "language":
      return tr("responsesExtraLanguageLabel");
    case "signers":
      return tr("responsesExtraSignersLabel");
    case "status":
      return tr("responsesExtraStatusLabel");
    case "email":
      return tr("responsesSignersEmailLabel");
    default:
      assertUnreachable(id);
  }
};

type Extras = "signers" | "date" | "status" | "language" | "email";

interface ExtraColumnData {
  id: Extras;
  default?: boolean;
}

const extraColumns: ExtraColumnData[] = [
  { id: "signers" },
  { id: "date" },
  { id: "status" },
  { id: "language" },
  //  { id: "email" }
];

type Filter = "hideUnanswered";

interface FilterColumnData {
  id: Filter;
  default?: boolean;
}
const dataFilters: FilterColumnData[] = [{ id: "hideUnanswered" }];

interface OutProps {
  customerId: string;
  studentGroupId: string;
  schoolUnitId: string;
  pathWithoutPublication: string;
  showGroupsWithoutPublications?: boolean;
  sentFormsMetadata:
    | gqltypes.schools_organisation_schoolUnits_classes_sentFormsMetadata
    | undefined;
}

interface StateProps {
  userPermissions: UserPermissions | null;
}

interface Props extends Translation, AdminContext, StateProps, OutProps {
  location: Location;
  params: Params;
  navigate: NavigateFunction;
  expired: boolean;
  showGroupsWithoutPublications?: boolean;
}

interface LocationState {
  columns?: State["columns"];
  fields?: State["fields"];
  filters?: State["filters"];
  isAllQuestionsChecked?: State["isAllQuestionsChecked"];
  sortOrder?: TableSortOrder[];
  expiredState: boolean;
  selectedPublication: string;
}

interface State {
  expired: boolean;
  selectedPublication?: string;
  columns: { [key: string]: boolean };
  fields: { [key: string]: boolean };
  filters: { [key: string]: boolean };
  isAllQuestionsChecked: boolean;
  studentGroup?: gqltypes.StudentGroup;
  sentFormsMetaData: gqltypes.schools_organisation_schoolUnits_classes_sentFormsMetadata;
}

const StudentGroupOverviewInner: React.FC<Props> = (props) => {
  const {
    tr,
    location,
    navigate,
    expired,
    sentFormsMetadata,
    customerId,
    studentGroupId,
    adminContext,
    schoolUnitId,
    showGroupsWithoutPublications,
  } = props;

  const { data, loading, error, refetch } = useQuery<
    gqltypes.studentGroupForms,
    gqltypes.studentGroupFormsVariables
  >(STUDENT_GROUP_FORMS_QUERY, {
    variables: {
      groupId: studentGroupId,
      customerId: customerId,
      context: adminContext,
      expired: expired,
    },
    fetchPolicy: "cache-first",
  });

  const path: string = location.pathname;
  const [state, setState] = useState<State>({
    expired: expired,
    selectedPublication: "",
    columns: {},
    fields: {},
    filters: {},
    isAllQuestionsChecked: false,
    studentGroup: undefined,
    sentFormsMetaData:
      sentFormsMetadata as gqltypes.schools_organisation_schoolUnits_classes_sentFormsMetadata,
  });
  const [isStateRestored, setIsStateRestored] = useState(false);
  const [activeTooltipOpen, setActiveTooltipOpen] = useState(false);
  const [expiredTooltipOpen, setExpiredTooltipOpen] = useState(false);
  const newTableRef = React.useRef<HTMLDivElement | null>(null);

  const updateStateFromUrl = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    const expired: boolean = queryParams.get("expired") === "true";
    const publicationId = queryParams.get("publicationId");
    const columns = JSON.parse(queryParams.get("columns") || "{}");
    const fields = JSON.parse(queryParams.get("fields") || "{}");
    const filters = JSON.parse(queryParams.get("filters") || "{}");

    setState((prevState) => ({
      ...prevState,
      expired,
      selectedPublication: publicationId || undefined,
      columns,
      fields,
      filters,
      sentFormsMetaData:
        sentFormsMetadata as gqltypes.schools_organisation_schoolUnits_classes_sentFormsMetadata,
    }));

    refetch({ expired }).then(({ data }) => {
      if (data && data.studentGroup) {
        const publications: gqltypes.studentGroupForms_studentGroup_publications[] =
          data.studentGroup.publications;
        const isPublicationValid: boolean = publications.some(
          (pub: gqltypes.studentGroupForms_studentGroup_publications) =>
            pub.id === publicationId
        );

        if (!isPublicationValid) {
          const validExpiredPublication:
            | gqltypes.studentGroupForms_studentGroup_publications
            | undefined = publications.find(
            (pub: gqltypes.studentGroupForms_studentGroup_publications) =>
              new Date(pub.validTo) < new Date()
          );

          if (validExpiredPublication) {
            setState((prevState: State) => ({
              ...prevState,
              selectedPublication: validExpiredPublication.id,
              columns: {},
              studentGroup:
                data.studentGroup as unknown as gqltypes.StudentGroup,
              sentFormsMetaData:
                sentFormsMetadata as gqltypes.schools_organisation_schoolUnits_classes_sentFormsMetadata,
            }));
          } else if (publications.length > 0) {
            // Fallback to the first available publication
            setState((prevState: State) => ({
              ...prevState,
              selectedPublication: publications[0]?.id || undefined,
              columns: {},
              studentGroup:
                data.studentGroup as unknown as gqltypes.StudentGroup,
              sentFormsMetaData:
                sentFormsMetadata as gqltypes.schools_organisation_schoolUnits_classes_sentFormsMetadata,
            }));
          } else {
            // No publications available
            setState((prevState: State) => ({
              ...prevState,
              selectedPublication: undefined,
              columns: {},
              studentGroup:
                data.studentGroup as unknown as gqltypes.StudentGroup,
              sentFormsMetaData:
                sentFormsMetadata as gqltypes.schools_organisation_schoolUnits_classes_sentFormsMetadata,
            }));

            // Update the URL to remove the publicationId
            navigate({
              pathname: path,
              search: buildUrlParams({
                publicationId: "",
                expired: expired,
              }),
            });
          }
        } else {
          setState((prevState: State) => ({
            ...prevState,
            studentGroup: data.studentGroup as unknown as gqltypes.StudentGroup,
            sentFormsMetaData:
              sentFormsMetadata as gqltypes.schools_organisation_schoolUnits_classes_sentFormsMetadata,
          }));
        }
      }
    });
  }, [location.search, refetch, sentFormsMetadata, navigate, path]);

  useEffect(() => {
    updateStateFromUrl();
  }, [updateStateFromUrl]);

  const saveStateToLocalStorage = useCallback(() => {
    const { selectedPublication, columns, studentGroup } = state;
    const stateToSave: string = JSON.stringify({
      selectedPublication,
      columns,
      studentGroup,
    });
    persistance.set("studentGroupResponsesState", stateToSave);
  }, [state]);

  const restoreStateFromLocalStorage = useCallback(() => {
    const savedState: string | null = persistance.get(
      "studentGroupResponsesState"
    );
    if (savedState) {
      const { selectedPublication, columns, studentGroup, sentFormsMetaData } =
        JSON.parse(savedState);
      setState((prevState) => ({
        ...prevState,
        selectedPublication,
        columns,
        studentGroup,
        sentFormsMetaData,
      }));
    }
    setIsStateRestored(true);
  }, []);

  useEffect(() => {
    restoreStateFromLocalStorage();
  }, [restoreStateFromLocalStorage]);

  const checkIfAllColumnsChecked = useCallback(() => {
    const allChecked: boolean = Object.values(state.columns).every(
      (checked) => checked
    );

    setState((prevState) => ({
      ...prevState,
      isAllQuestionsChecked: allChecked,
    }));
  }, [state.columns]);

  useEffect(() => {
    if (isStateRestored) {
      checkIfAllColumnsChecked();
    }
  }, [isStateRestored, state.columns, checkIfAllColumnsChecked]);

  useEffect(() => {
    saveStateToLocalStorage();
  }, [saveStateToLocalStorage]);

  const mergeLocationState = useCallback(
    (state: Partial<LocationState>) => {
      const currentState: LocationState =
        (location.state as LocationState) || {};
      const newState = { ...currentState, ...state };

      if (newState.selectedPublication !== currentState.selectedPublication) {
        navigate(path, {
          state: newState,
          replace: true,
        });
      }
    },
    [location.state, path, navigate]
  );

  const buildUrlParams = (params: {
    publicationId: string | undefined;
    expired: boolean | undefined;
  }) => {
    const urlParams = new URLSearchParams();
    urlParams.set("publicationId", params.publicationId || "");
    urlParams.set("expired", params.expired?.toString() || "false");

    return urlParams.toString();
  };

  const toggleExpired = useCallback(
    (expired: boolean) => {
      setState((prevState) => ({
        ...prevState,
        expired,
      }));

      navigate({
        pathname: path,
        search: buildUrlParams({
          publicationId: state.selectedPublication || "",
          expired: expired,
        }),
      });

      refetch({ expired });
    },
    [state.selectedPublication, navigate, path, refetch]
  );

  useEffect(() => {
    if (
      state.selectedPublication !== undefined &&
      state.expired !== undefined
    ) {
      navigate({
        pathname: path,
        search: buildUrlParams({
          publicationId: state.selectedPublication || "",
          expired: state.expired,
        }),
      });

      refetch({ expired: state.expired });
    }
  }, [state.selectedPublication, state.expired, navigate, path, refetch]);

  const handleChangePublication = useCallback(
    (value: string) => {
      const newPublicationId: string = value || "";

      if (state.selectedPublication !== newPublicationId) {
        navigate({
          pathname: path,
          search: buildUrlParams({
            publicationId: newPublicationId,
            expired: state.expired,
          }),
        });

        setState((prevState) => ({
          ...prevState,
          selectedPublication: newPublicationId,
          columns: {},
          fields: {},
          filters: { hideUnanswered: false },
        }));

        checkIfAllColumnsChecked();
      }
    },
    [
      state.selectedPublication,
      state.expired,
      navigate,
      path,
      checkIfAllColumnsChecked,
    ]
  );

  const setCheckAll = useCallback(
    (checked: boolean) => {
      const newColumns = Object.keys(state.columns).reduce((acc, key) => {
        acc[key] = checked;
        return acc;
      }, {} as { [key: string]: boolean });
      setState((prevState) => ({
        ...prevState,
        isAllQuestionsChecked: checked,
        columns: newColumns,
      }));
    },
    [state.columns]
  );

  const handleChangeColumns = useCallback(
    (id: string, checked: boolean) => {
      setState((prevState: State) => ({
        ...prevState,
        columns: { ...prevState.columns, [id]: checked },
      }));
      checkIfAllColumnsChecked();
    },
    [checkIfAllColumnsChecked]
  );

  const handleChangeFields = useCallback((id: string, checked: boolean) => {
    setState((prevState: State) => ({
      ...prevState,
      fields: { ...prevState.fields, [id]: checked },
    }));
  }, []);

  const handleChangeFilters = useCallback((id: string, checked: boolean) => {
    setState((prevState: State) => ({
      ...prevState,
      filters: { ...prevState.filters, [id]: checked },
    }));
  }, []);

  const viewForm = useCallback(
    (id: string) => {
      navigate(links.admin.publication.response(id));
    },
    [navigate]
  );

  const filterOutQuestions = useCallback(
    (
      components: gqltypes.studentGroupForms_studentGroup_publications_form_componentData_components[],
      predicateComponents:
        | gqltypes.studentGroupForms_studentGroup_publications_form_componentData_predicateComponents[]
        | null
    ) => {
      if (components) {
        const questions = f.flattenAll(
          components.map((c) =>
            c.questions.map((q) => {
              const predQuestions = allPredicateQuestionsAndComponents(
                predicateComponents || [],
                q
              ).map(({ question: q2, predicateComponent: pc }) => ({
                component: c,
                question: q2,
                pc,
              }));
              return [{ component: c, question: q }, predQuestions];
            })
          )
        );

        return questions;
      }

      return [];
    },
    []
  );

  const manuallyAddSelectedQuestions = useCallback(
    (form: gqltypes.studentGroupForms_studentGroup_publications_form) => {
      if (form && state.isAllQuestionsChecked) {
        const components: gqltypes.studentGroupForms_studentGroup_publications_form_componentData_components[] =
          form.componentData.components;
        const predicateComponents:
          | gqltypes.studentGroupForms_studentGroup_publications_form_componentData_predicateComponents[]
          | null = form.componentData.predicateComponents;
        const questions = filterOutQuestions(components, predicateComponents);

        const mapQuestions: { [key: string]: boolean } = {};
        questions.forEach((q) => {
          mapQuestions[q.question.id.toString()] = true;
        });
        setState((prevState) => ({
          ...prevState,
          columns: mapQuestions,
        }));
      }
    },
    [state.isAllQuestionsChecked, filterOutQuestions]
  );

  useEffect(() => {
    if (data?.studentGroup) {
      const form:
        | gqltypes.studentGroupForms_studentGroup_publications_form
        | undefined = data.studentGroup.publications.find(
        (pub: gqltypes.studentGroupForms_studentGroup_publications) =>
          pub.id === state.selectedPublication
      )?.form;
      if (form) {
        manuallyAddSelectedQuestions(form);
      }
    }
  }, [
    data?.studentGroup,
    state.selectedPublication,
    manuallyAddSelectedQuestions,
  ]);

  const locState: LocationState | null = location.state as LocationState;

  if (error) {
    return (
      <main className="top content">
        <h1>{tr("failedToFetchData")}</h1>
      </main>
    );
  }

  if (
    loading ||
    !data ||
    !data.studentGroup ||
    props.userPermissions === null
  ) {
    return (
      <main className="top content">
        <Loading />
      </main>
    );
  }

  const suResouceId: string = permissionResourceIdFromSchoolUnit(
    adminContext.org!,
    {
      id: schoolUnitId,
      customerId: customerId,
    }
  );

  const schoolUnitPermissions = props.userPermissions.su[suResouceId] || {};

  const publications: gqltypes.studentGroupForms_studentGroup_publications[] =
    data.studentGroup.publications;
  const studentGroup: gqltypes.studentGroupForms_studentGroup =
    data.studentGroup;
  const forms = data.studentGroup.publications.map((p) => p.form);
  const selectedPublication:
    | gqltypes.studentGroupForms_studentGroup_publications
    | undefined = publications.find((p) => p.id === state.selectedPublication);

  const components: gqltypes.studentGroupForms_studentGroup_publications_form_componentData_components[] =
    selectedPublication
      ? selectedPublication.form.componentData.components
      : [];

  const predicateComponents: gqltypes.studentGroupForms_studentGroup_publications_form_componentData_predicateComponents[] =
    selectedPublication
      ? selectedPublication.form.componentData.predicateComponents || []
      : [];

  const selectedQuestions: gqltypes.FormQuestion[] =
    [] as gqltypes.FormQuestion[];
  const questions = filterOutQuestions(components, predicateComponents);

  const selectedComponentsMap: any = {};
  questions.forEach(({ component, question }) => {
    let c = selectedComponentsMap[component.id];
    if (!c) {
      selectedComponentsMap[component.id] = { ...component, questions: [] };
      c = selectedComponentsMap[component.id];
    }
    c.questions.push(question);
  });
  const selectedComponents: gqltypes.FormComponent[] = Object.values(
    selectedComponentsMap
  );

  const selectedPredicateComponents: gqltypes.FormComponent[] = questions
    .filter(({ pc }) => pc)
    .map(({ pc }) => pc);

  questions.forEach(({ question }) => f.push(selectedQuestions, question));

  if (questions.length !== 0 && Object.keys(state.columns).length === 0) {
    const selectedQuestions: { [key: string]: boolean } = questions.reduce(
      (selectedQuestions, item) => {
        selectedQuestions[item.question.id] = false;
        return selectedQuestions;
      },
      {} as { [key: string]: boolean }
    );

    setState((prevState: State) => ({
      ...prevState,
      columns: selectedQuestions,
      isAllQuestionsChecked: false,
    }));
  }

  const selectedExtras: ExtraColumnData[] = extraColumns.filter(
    (col) => state.fields[col.id] === true
  );

  const options = publications.map((p) => ({
    value: p.id,
    label: `${p.name} - ${p.form.name}`,
    validTo: p.validTo,
  }));

  const filterTranslation: { [key in Filter]: string } = {
    hideUnanswered: tr("responsesFilterHideUnansweredLabel"),
  };

  const {
    expiredPublications = 0,
    activePublications = 0,
    publications: totalPublications,
  } = state.sentFormsMetaData || {};

  const getTableWithoutShowAnswer = (): HTMLElement | null => {
    if (!newTableRef.current) return null;

    const tableClone = newTableRef.current.cloneNode(true) as HTMLElement;
    const rows = tableClone.querySelectorAll("tr");

    rows.forEach((row) => {
      // Remove the last cell in each row
      const lastCell = row.lastElementChild;
      if (lastCell) {
        lastCell.remove();
      }
    });

    return tableClone;
  };

  const toggleActiveTooltip = () => {
    setActiveTooltipOpen(!activeTooltipOpen);
  };

  const toggleExpiredTooltip = () => {
    setExpiredTooltipOpen(!expiredTooltipOpen);
  };

  return (
    <>
      {showGroupsWithoutPublications ||
      totalPublications ||
      activePublications ||
      expiredPublications ||
      forms.length > 0 ? (
        <ISTContainer
          id="student-group-responses"
          header={studentGroup ? studentGroup.displayName : ""}
        >
          <>
            <div className="mt-4 mb-3 ml-3 d-flex">
              <button
                id="activePublicationsButton"
                className={`pr-5 pl-5 border-0 rounded-left ${
                  !state.expired ? "active-button" : "unactive-button"
                }`}
                onClick={() => toggleExpired(false)}
              >
                {tr(
                  "publicationViewAnswersActivePublicationsCount",
                  activePublications
                )}
              </button>
              <Tooltip
                target="activePublicationsButton"
                isOpen={activeTooltipOpen}
                toggle={toggleActiveTooltip}
              >
                {tr("tooltipTextForActivePublicationsButton")}
              </Tooltip>

              <button
                id="expiredPublicationsButton"
                className={`pr-5 pl-5 border-0 rounded-right ${
                  state.expired ? "active-button" : "unactive-button"
                }`}
                onClick={() => toggleExpired(true)}
              >
                {tr(
                  "publicationViewAnswersExpiredPublicationsCount",
                  expiredPublications
                )}
              </button>
              <Tooltip
                target="expiredPublicationsButton"
                isOpen={expiredTooltipOpen}
                toggle={toggleExpiredTooltip}
              >
                {tr("tooltipTextForExpiredPublicationsButton")}
              </Tooltip>
            </div>

            <form className="p-content">
              <FormSelectGroupedCategories
                label={tr("publication")}
                value={state.selectedPublication}
                onChange={handleChangePublication}
                options={options}
                isExpired={state.expired}
              />
            </form>
          </>

          {selectedPublication ? (
            <div>
              <div className="p-content">
                <h4>{tr("responsesFilterHeader")}</h4>
                <div className="row mb-content">
                  {dataFilters.map((e) => (
                    <div
                      key={e.id}
                      className="col-12 col-sm-6 col-md-4 col-lg-3"
                    >
                      <Checkbox
                        id={e.id}
                        onChange={handleChangeFilters}
                        label={filterTranslation[e.id]}
                        checked={state.filters[e.id] === true}
                      />
                    </div>
                  ))}
                </div>

                <hr />
                <h4>{tr("responsesShowFieldsHeader")}</h4>
                <div className="row mb-content">
                  {extraColumns.map((e) => (
                    <div
                      key={e.id}
                      className="col-12 col-sm-6 col-md-4 col-lg-3"
                    >
                      <Checkbox
                        id={e.id}
                        onChange={handleChangeFields}
                        label={getExtraColumnLabel(tr, e.id)}
                        checked={state.fields[e.id] === true}
                      />
                    </div>
                  ))}
                </div>

                <hr />
                <h4>{tr("responsesQuestionsHeader")}</h4>
                {components.some((c) => c.questions.length > 0) ? (
                  <div className="mb-content">
                    <Checkbox
                      id="isAllQuestionsChecked"
                      onChange={(id, checked) => setCheckAll(checked)}
                      checked={state.isAllQuestionsChecked}
                      label={tr("reportMarkAllAnswers")}
                    />
                  </div>
                ) : null}
                {components.map((c) => {
                  return (
                    <div key={c.id} className="mb-content">
                      <span>
                        <strong>{c.title} </strong>
                      </span>
                      <div className="row">
                        {c.questions.map((q) => {
                          const questionLabel = (
                            question: gqltypes.FormQuestion
                          ) =>
                            (question.shortName
                              ? `${question.shortName} - `
                              : "") + question.question;
                          return (
                            <React.Fragment key={q.id}>
                              <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                <Checkbox
                                  onChange={handleChangeColumns}
                                  label={questionLabel(q)}
                                  checked={
                                    state.isAllQuestionsChecked
                                      ? true
                                      : state.columns[q.id] === true
                                  }
                                  id={q.id}
                                />
                              </div>
                              {allPredicateQuestions(
                                predicateComponents,
                                q
                              ).map((q2) => (
                                <div
                                  key={q2.id}
                                  className="col-12 col-sm-6 col-md-4 col-lg-3"
                                >
                                  <Checkbox
                                    onChange={handleChangeColumns}
                                    label={questionLabel(q2)}
                                    checked={
                                      state.isAllQuestionsChecked
                                        ? true
                                        : state.columns[q2.id] === true
                                    }
                                    id={q2.id}
                                  />
                                </div>
                              ))}
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
              <>
                <div className="p-content">
                  <ClipboardCopyButton
                    getElementToCopy={getTableWithoutShowAnswer}
                    style={{
                      backgroundColor: "#007E94",
                      color: "#FFFFFF",
                      fontWeight: "light",
                    }}
                    tooltipText={tr("publicationAnswersCopyTableTooltip")}
                  />
                </div>
              </>
              <NewTable
                ref={newTableRef}
                customerId={studentGroup.customerId}
                groupId={studentGroup.id}
                publicationId={selectedPublication?.id || ""}
                components={components}
                selectedComponents={selectedComponents}
                selectedPredicateComponents={selectedPredicateComponents}
                selectedQuestions={selectedQuestions}
                selectedQuestionsState={state.columns}
                selectedExtraColumns={selectedExtras}
                filters={state.filters as any}
                viewForm={viewForm}
                permissions={schoolUnitPermissions}
                sortOrderChanged={(order) => {
                  mergeLocationState({ sortOrder: order });
                }}
                initialSortOrder={locState ? locState.sortOrder || [] : []}
                expired={state.expired}
              />
            </div>
          ) : null}
        </ISTContainer>
      ) : null}
    </>
  );
};

export const StudentGroupResponses = _.flowRight(
  withTranslation,
  withAdminContext,
  withRouter,
  connect<StateProps, any, OutProps, StoreState>((state) => ({
    userPermissions: state.app.permissions,
  }))
)(StudentGroupOverviewInner) as React.ComponentType<OutProps>;
